import React, { useState ,useEffect} from "react";
import Price from "./Price";
import RecentlyListed from "./Listed/RecentlyListed";




const InventorySidebar = ({ inventory, applyFilters , value, onChange}) => {
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [selectedModels, setSelectedModels] = useState([]);
  
  const [allBrands, setAllBrands] = useState([]);
  const [allLocations, setAllLocations] = useState([]);
  const [allTypes, setAllTypes] = useState([]);
  const [allModels, setAllModels] = useState([]);

  useEffect(() => {
    const getUniqueValues = (property) => {
      return inventory.reduce((acc, item) => {
        if (item[property] && item[property].name && !acc.includes(item[property].name)  ) {
          acc.push(item[property].name);
        }
     
        return acc;
      }, []);
    };
  
    setAllBrands(getUniqueValues("brand"));
    setAllLocations(getUniqueValues("location"));
    setAllTypes(getUniqueValues("type"));
    // setAllModels(getUniqueValues("car_model"));
  }, [inventory]);
  

  useEffect(() => {
    const getUniqueValues = (property) => {
      return inventory.reduce((acc, item) => {
        if (item[property] && item[property] && !acc.includes(item[property])  ) {
          acc.push(item[property]);
        }
        return acc;
      }, []);
    };
  
   setAllModels(getUniqueValues("car_model"));
  }, [inventory]);   
  
  
  

  const handleBrandSelect = (brand) => {
    const updatedBrands = selectedBrands.includes(brand)
      ? selectedBrands.filter((b) => b !== brand)
      : [...selectedBrands, brand];
    setSelectedBrands(updatedBrands);
    applyFilters({ selectedBrands: updatedBrands });
  };

  const handleLocationSelect = (location) => {
    const updatedLocations = selectedLocations.includes(location)
      ? selectedLocations.filter((l) => l !== location)
      : [...selectedLocations, location];
    console.log("Selected Locations:", updatedLocations); 
    setSelectedLocations(updatedLocations);
    applyFilters({ selectedLocations: updatedLocations });
  };
  
  const handleTypeSelect = (type) => {
    const updatedTypes = selectedTypes.includes(type)
      ? selectedTypes.filter((t) => t !== type)
      : [...selectedTypes, type];
    console.log("Selected Types:", updatedTypes); 
    setSelectedTypes(updatedTypes);
    applyFilters({ selectedTypes: updatedTypes });
  };
  

  const handleModelSelect = (model) => {
    const updatedModels = selectedModels.includes(model)
      ? selectedModels.filter((m) => m !== model)
      : [...selectedModels, model];
    
    setSelectedModels(updatedModels);
    console.log("Selected model:", updatedModels); 
    applyFilters({ selectedModels: updatedModels });
  };
  

  return (
    <>
      <div id="aside" className="col-md-3">
      {/* Location */}
      <div className="aside">
        <h3 className="aside-title">Location</h3>
        <div className="checkbox-filter">
          {allLocations.map((location) => (
            <div className="input-checkbox" key={location}>
              <input
                type="checkbox"
                id={`location-${location}`}
                checked={selectedLocations.includes(location)}
                onChange={() => handleLocationSelect(location)}
              />
              <label htmlFor={`location-${location}`}>
                <span></span>
                {location}
              </label>
            </div>
          ))}
        </div>
      </div>

        {/* Categories */}
        <div className="aside">
          <h3 className="aside-title">Types</h3>
          <div className="checkbox-filter">
          {allTypes.map((type) => (
            <div className="input-checkbox" key={type}>
              <input
                type="checkbox"
                id={`type-${type}`}
                checked={selectedTypes.includes(type)}
                onChange={() => handleTypeSelect(type)}
              />
              <label htmlFor={`type-${type}`}>
                <span></span>
                {type}
              </label>
            </div>
          ))}
        </div>
        </div>

        <div className="aside">
          <h3 className="aside-title">Price</h3>
          <div className="price-filter">
            <Price inventory={inventory} value={value} onChange={onChange} />
          </div>
        </div>

        {/* Model */}
        <div className="aside">
          <h3 className="aside-title">Model</h3>
          <div className="checkbox-filter">
          {allModels.map((model) => (
            <div className="input-checkbox" key={model}>
              <input
                type="checkbox"
                id={`model-${model}`}
                checked={selectedModels.includes(model)}
                onChange={() => handleModelSelect(model)}
              />
              <label htmlFor={`model-${model}`}>
                <span></span>
                {model}
              </label>
            </div>
          ))}
        </div>
        </div>

        

        {/* Brand */}
        <div className="aside">
          <h3 className="aside-title">Brand</h3>
          <div className="checkbox-filter">
          {allBrands.map((brand) => (
            <div className="input-checkbox" key={brand}>
              <input
                type="checkbox"
                id={`brand-${brand}`}
                checked={selectedBrands.includes(brand)}
                onChange={() => handleBrandSelect(brand)}
              />
              <label htmlFor={`brand-${brand}`}>
                <span></span>
                {brand}
              </label>
            </div>
          ))}
        </div>
        </div>

        {/* Recently Listed */}
        <div className="aside">
          <h3 className="aside-title">Recently Listed</h3>
          <RecentlyListed />
        </div>

        {/* Price */}
      
      </div>
    </>
  );
};

export default InventorySidebar;

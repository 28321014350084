import React, { useState, useEffect } from "react";
import axios from "axios";
import {  Link } from "react-router-dom";
import InventorySidebar from "./InventorySidebar";

export default function Inventory() {
  const [inventory, setInventory] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [error, setError] = useState(null);
  const [priceRange, setPriceRange] = useState([0, 1000000]);
  const [filteredData, setFilteredData] = useState([]);

  const fetchData = async (pageNumber) => {
    try {
      const response = await axios.get(
        `https://api.autosansar.com/api/vehicles/search?page=${pageNumber}&limit=10&orderBy=created_at DESC`
      );
      setInventory(response.data.data);
      setTotalPages(response.data.meta.last_page);
      setPage(pageNumber);
    } catch (error) {
      setError(error);
    }
  };

  const handlePrevious = () => {
    if (page > 1) {
      fetchData(page - 1);
    }
  };

  const handleNext = () => {
    if (page < totalPages) {
      fetchData(page + 1);
    }
  };

  const handlePageChange = (pageNumber) => {
    fetchData(pageNumber);
  };

  useEffect(() => {
    fetchData(page);
  }, [page]);

  useEffect(() => {
    const filteredData = inventory.filter((item) => {
      return item.price >= priceRange[0] && item.price <= priceRange[1];
    });
    setFilteredData(filteredData);
  }, [inventory, priceRange]);

  const handlePriceChange = (newValue) => {
    setPriceRange(newValue);
  };


  // Function to handle filtering
  const applyFilters = (filters) => {
    console.log("Filters:", filters); // Log filters object for debugging
    
    const { selectedBrands, selectedLocations, selectedTypes, selectedModels } = filters || {};
  
    const filteredInventory = inventory.filter((item) => {
      const brandMatch = !selectedBrands || selectedBrands.length === 0 || selectedBrands.includes(item.brand?.name);
      const locationMatch = !selectedLocations || selectedLocations.length === 0 || selectedLocations.includes(item.location?.name);
      const typeMatch = !selectedTypes || selectedTypes.length === 0 || selectedTypes.includes(item.type?.name);
      const modelMatch = !selectedModels || selectedModels.length === 0 || selectedModels.includes(item.car_model); 
      
      return brandMatch && locationMatch && typeMatch && modelMatch;
    });
    
  
    setFilteredData(filteredInventory);
};

  

  // Update filtered data whenever inventory or sidebar filters change
  useEffect(() => {
    setFilteredData(inventory); // Initialize filteredData with all inventory data
  }, [inventory]);

  return (
    <>
      {/* BREADCRUMB */}
      <div id="breadcrumb" className="section">
        {/* <!-- container --> */}
        <div className="container">
          {/* <!-- row --> */}
          <div className="row">
            <div className="col-md-12">
              <h3 className="breadcrumb-header">Regular Page</h3>
              <ul className="breadcrumb-tree">
                <li>
                  <a href="/">Home</a>
                </li>
                <li className="active">Inventory</li>
              </ul>
            </div>
          </div>
          {/* <!-- /row --> */}
        </div>
        {/* <!-- /container --> */}
      </div>
      {/* /BREADCRUMB */}

      {/* SECTION */}
      <div className="section">
        <div className="container">
          <div className="row">
            <InventorySidebar inventory={inventory} applyFilters={applyFilters} value={priceRange} onChange={handlePriceChange} />

            <div id="store" className="col-md-9">
              <div className="row">
                {(
                
                  filteredData.length > 0
                  ? filteredData
                  : inventory
                ).map((item) => (
                  <div className="col-md-4 col-xs-6" key={item.id}>
                    <div className="product">
                      {/* Product details */}
                      <div className="product-img">
                        {item.vehiclephotos.length > 0 && (
                          <img
                            src={`https://api.autosansar.com/${item.vehiclephotos[0].name}`}
                            className="p-image"
                            alt=""
                          />
                        )}
                        <div className="product-label">
                          <span className="new">{item.condition.name}</span>
                        </div>
                      </div>
                      <div className="product-body">
                        <p className="product-category">{item.type.name}</p>
                        <h3 className="product-name">
                          <a href="/">
                            {item.brand?.name} {item.car_model}
                          </a>
                        </h3>
                        <h4 className="product-price">
                          {item.price}{" "}
                          <del className="product-old-price">
                            {item.oldprice}
                          </del>
                        </h4>
                        <div className="product-btns">
                          <button className="add-to-wishlist">
                            <i className="fa fa-heart-o"></i>
                            <span className="tooltipp">add to wishlist</span>
                          </button>
                          <button className="add-to-compare">
                            <i className="fa fa-exchange"></i>
                            <span className="tooltipp">add to compare</span>
                          </button>
                        </div>
                        <div className="product-rating"></div>
                        <div className="add-to-cart">
                          <Link to={`/Inventory_single/${item.id}`}>
                            <button className="add-to-cart-btn">
                              <i className="fa fa-eye"></i> View now
                            </button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              {/* Pagination */}
              <div className="store-filter clearfix">
                <span className="store-qty">
                  {totalPages !== undefined && inventory.length > 0
                    ? `Showing ${Math.min(
                        (page - 1) * 10 + 1,
                        totalPages * 10
                      )}-${Math.min(page * 10, totalPages * 10)} Products`
                    : " "}
                </span>
                <div className="store-pagination">
                  <button
                    disabled={page <= 1}
                    onClick={handlePrevious}
                    className="btn"
                  >
                    &laquo;
                  </button>
                  {[...Array(totalPages).keys()].map((pageNumber) => (
                    <button
                      key={pageNumber + 1}
                      disabled={pageNumber + 1 === page}
                      onClick={() => handlePageChange(pageNumber + 1)}
                      className="btn"
                    >
                      {pageNumber + 1}
                    </button>
                  ))}
                  <button
                    disabled={page >= totalPages}
                    onClick={handleNext}
                    className="btn"
                  >
                    &raquo;
                  </button>
                </div>
              </div>
              {/* /Pagination */}
            </div>
          </div>
        </div>
      </div>
      {/* /SECTION */}
    </>
  );
}

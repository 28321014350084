import React, { useState, useEffect } from "react";
import Price from "../Price";
import axios from "axios";
import { Link } from "react-router-dom";
import AccessoriesSingle from "./AccessoriesSingle";
import AccessoriesSidebar from "./AccessoriesSidebar";
// import RecentlyListed from "./Listed/RecentlyListed";

export default function Accessories() {
  // const [Loading,setLoading]= useState(true);
  const [accessory, setAccessory] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [error, setError] = useState(null);
  const [priceRange, setPriceRange] = useState([0, 1000000]);
  const [filteredData, setFilteredData] = useState([]);

  const fetchData = async (pageNumber) => {
    try {
      const response = await axios.get(
        `https://api.autosansar.com/api/accessories/search?page=${pageNumber}&limit=10&orderBy=created_at DESC`
      );
      setAccessory(response.data.data);
      setTotalPages(response.data.meta.last_page);
      setPage(pageNumber);
    } catch (error) {
      setError(error);
    }
  };

  const handlePrevious = () => {
    if (page > 1) {
      fetchData(page - 1);
    }
  };

  const handleNext = () => {
    if (page < totalPages) {
      fetchData(page + 1);
    }
  };

  const handlePageChange = (pageNumber) => {
    fetchData(pageNumber);
  };

  useEffect(() => {
    fetchData(page);
  }, [page]);

 
  // useEffect(() => {
  //   axios
  //     .get("https://autosansar.com/api/vehicles?limit=10")
  //     .then((res) => {
  //       console.log(res);
  //       setInventory(res.data.data);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }, []);

  useEffect(() => {
    const filteredData = accessory.filter((item) => {
      return item.price >= priceRange[0] && item.price <= priceRange[1];
    });
    setFilteredData(filteredData);
  }, [accessory, priceRange]);

  const handlePriceChange = (newValue) => {
    setPriceRange(newValue);
  };

  const applyFilters = (filters) => {
    console.log("Filters:", filters); 
    
    const { selectedLocations, selectedTypes } = filters || {};
  
    const filteredAccessories = accessory.filter((item) => {
     
      const locationMatch = !selectedLocations || selectedLocations.length === 0 || selectedLocations.includes(item.location?.name);
      const typeMatch = !selectedTypes || selectedTypes.length === 0 || selectedTypes.includes(item.accessorycategory?.name);
   
      
      return  locationMatch && typeMatch ;
    });
    
  
    setFilteredData(filteredAccessories);
};

  


  useEffect(() => {
    setFilteredData(accessory);
  }, [accessory]);



  return (
    <>
      {/* <!-- BREADCRUMB --> */}
      <div id="breadcrumb" className="section">
        {/* <!-- container --> */}
        <div className="container">
          {/* <!-- row --> */}
          <div className="row">
            <div className="col-md-12">
              <h3 className="breadcrumb-header">Regular Page</h3>
              <ul className="breadcrumb-tree">
                <li>
                  <a href="/">Home</a>
                </li>
                <li className="active">Accessories</li>
              </ul>
            </div>
          </div>
          {/* <!-- /row --> */}
        </div>
        {/* <!-- /container --> */}
      </div>
      {/* <!-- /BREADCRUMB --> */}

      {/* <!-- SECTION --> */}
      <div className="section">
        {/* <!-- container --> */}
        <div className="container">
          {/* <!-- row --> */}
          <div className="row">
            {/* <!-- ASIDE --> */}
            <div id="aside" className="col-md-3">
              {/* <!-- aside Widget --> */}
            <AccessoriesSidebar accessory={accessory} applyFilters={applyFilters} value={priceRange} onChange={handlePriceChange}/>
              {/* <!-- /aside Widget --> */}
            </div>
            {/* <!-- /ASIDE --> */}

            {/* <!-- STORE --> */}
            <div id="store" className="col-md-9">
            <div className="row">
                {(
                
                  filteredData.length > 0
                  ? filteredData
                  : accessory
                ).map((item) => (
                  <div className="col-md-4 col-xs-6" key={item.id}>
                    <Link to={`/AccessoriesSingle/${item.id}`}>
                      <div className="product">
                        <div className="product-img">
                           {item.accessoryphotos.length > 0 && (
                             <img
                              
                               src={`https://api.autosansar.com/${item.accessoryphotos[0].name}`}
                               className="p-image"
                               alt=""
                             />
                          ) } 

                          {/* <div className="product-label">
                           
                             <span className="new">{item.condition.name}</span>
                           </div> */}
                        </div>
                        <div className="product-body">
                          <p className="product-category">
                            {/* {item.accessorycategory?.name} */}
                          </p>
                          <h3 className="product-name">
                            <a href="/">
                              {" "}
                              {item.name}
                              {"  "} {item.metaname}
                            </a>
                          </h3>
                          <h4 className="product-price">
                            {item.price}{" "}
                            {/* <del className="product-old-price"> {item.oldprice}</del> */}
                          </h4>

                          <div className="product-btns">
                            <button className="add-to-wishlist">
                              <i className="fa fa-heart-o"></i>
                              <span className="tooltipp">add to wishlist</span>
                            </button>
                            <button className="add-to-compare">
                              <i className="fa fa-exchange"></i>
                              <span className="tooltipp">add to compare</span>
                            </button>
                          </div>
                          <div className="product-rating"></div>
                          <div className="add-to-cart">
                            <Link to={`/AccessoriesSingle/${item.id}`}>
                              {" "}
                              <button className="add-to-cart-btn">
                                <i className="fa fa-eye"></i> View now
                              </button>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                ))}
              </div>

              <div className="row">
                {/* <!-- product --> */}


          
                {/* <!-- /product --> */}

                <div className="clearfix visible-sm visible-xs"></div>

                {/* <!-- /product --> */}
              </div>
              {/* <!-- /store products --> */}

              {/* <!-- store bottom filter --> */}
              <div className="store-filter clearfix">
                <span className="store-qty">
                  {totalPages !== undefined && accessory.length > 0
                    ? `Showing ${Math.min(
                        (page - 1) * 10 + 1,
                        totalPages * 10
                      )}-${Math.min(page * 10, totalPages * 10)} Products`
                    : "  "}
                </span>

                <div className="store-pagination">
                  <button
                    disabled={page <= 1}
                    onClick={handlePrevious}
                    className="btn"
                  >
                    &laquo;
                  </button>

                  {[...Array(totalPages).keys()].map((pageNumber) => (
                    <button
                      key={pageNumber + 1}
                      disabled={pageNumber + 1 === page}
                      onClick={() => handlePageChange(pageNumber + 1)}
                      className="btn"
                    >
                      {pageNumber + 1}
                    </button>
                  ))}

                  <button
                    disabled={page >= totalPages}
                    onClick={handleNext}
                    className="btn"
                  >
                    &raquo;
                  </button>
                </div>
              </div>
              {/* <!-- /store bottom filter --> */}
            </div>
            {/* <!-- /STORE --> */}
          </div>
          {/* <!-- /row --> */}
        </div>
        {/* <!-- /container --> */}
      </div>
      {/* <!-- /SECTION --> */}
    </>
  );
}

import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import PopularPostBlog from "./Listed/PopularPostBlog";

export default function Blog() {
  const [blog, setBlog] = useState([]);
  // const [Loading,setLoading]= useState(true);

  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [search, setSearch] = useState("");
  const [showResults, setShowResults] = useState(true);

  const handleChange = (e) => {
    setSearch(e.target.value);
  };

  useEffect(() => {
   
      let apiUrl;
      apiUrl = `https://www.nepalautotrader.com/api/blogs/search?search=${search}&&page=${page}`;
      fetch(apiUrl)
        .then((res) => res.json())
        .then((data) => {
          if (data && data.data && Array.isArray(data.data)) {
            setBlog(data.data);
          
               setTotal(data.meta.total);
                 setTotalPages(data.meta.last_page);
           
            setShowResults(true);
          } 
        })
        .catch((error) => {
          console.error("Error fetching inventory data:", error);
        
         
        });
    
  }, [search ,page]);

  // const [searchParam] = useState(["blog_title", "blog_description","slug"]);
  const handlePrevious = () => {
    axios
      .get(
        `https:nepalautotrader.com/api/blogs?page=${
          page - 1
        }&limit=24&orderBy=created_at DESC`
      )
      .then((res) => {
        console.log(res);
        setBlog(res.data.data);

        setPage(page - 1);
      })
      .catch((err) => {
        console.log(err);
      });

    console.log("a");
  };
  const handleNext = () => {
    if (page + 1 > Math.ceil(total / 20)) {
    } else {
      axios
        .get(
          `https://nepalautotrader.com/api/blogs?page=${
            page + 1
          }&limit=20&orderBy=created_at DESC`
        )
        .then((res) => {
          console.log(res);
          setBlog(res.data.data);
          setPage(page + 1);
        })
        .catch((err) => {
          console.log(err);
        });
      console.log("b");
    }
  };
  const [totalPages, setTotalPages] = useState(1);

  const handlePageChange = (pageNumber) => {
    axios
      .get(
        `https://nepalautotrader.com/api/blogs?page=${pageNumber}&limit=20&orderBy=created_at DESC`
      )
      .then((res) => {
        setBlog(res.data.data);
        setPage(pageNumber);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // useEffect(() => {
  //   axios
  //     .get(
  //       `https://nepalautotrader.com/api/blogs?page=${page}&limit=20&orderBy=created_at DESC`
  //     )
  //     .then((res) => {
  //       setBlog(res.data.data);
  //       setTotal(res.data.meta.total);
  //       setTotalPages(res.data.meta.last_page);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }, [page]);


  return (
    <>
      {/* <!-- BREADCRUMB --> */}
      <div id="breadcrumb" className="section">
        {/* <!-- container --> */}
        <div className="container">
          {/* <!-- row --> */}
          <div className="row">
            <div className="col-md-12">
              <h3 className="breadcrumb-header">Regular Page</h3>
              <ul className="breadcrumb-tree">
                <li>
                  <a href="/">Home</a>
                </li>
                <li className="active">Blog</li>
              </ul>
            </div>
          </div>
          {/* <!-- /row --> */}
        </div>
        {/* <!-- /container --> */}
      </div>
      {/* <!-- /BREADCRUMB --> */}

      {/* <!-- SECTION --> */}
      <div className="section">
        {/* <!-- container --> */}
        <div className="container">
          {/* <!-- row --> */}
          <div className="row">
            {/* <!-- ASIDE --> */}
            <div id="aside" className="col-md-3">
              {/* <!-- aside Widget --> */}

              <div className="aside">
                <h3 className="aside-title">Search Blog</h3>
                <div className="blog-search">
                  <form>
                    <input
                      className="input"
                      placeholder="Blog Search"
                      value={search}
                      onChange={handleChange}
                    />
                    <button className="search-btn">
                      <i className="fa-solid fa-magnifying-glass"></i>
                    </button>
                  </form>
                </div>
              </div>

              {/* <!-- SEARCH BAR --> */}

              {/* <!-- /SEARCH BAR --> */}
              {/* <div className="aside">
                <h3 className="aside-title">Categories</h3>
                <div className="checkbox-filter">
                  <div className="input-checkbox">
                    <input type="checkbox" id="category-1" />
                    <label htmlFor="category-1">
                      <span></span>
                      Laptops
                      <small>(120)</small>
                    </label>
                  </div>

                  <div className="input-checkbox">
                    <input type="checkbox" id="category-2" />
                    <label htmlFor="category-2">
                      <span></span>
                      Smartphones
                      <small>(740)</small>
                    </label>
                  </div>

                  <div className="input-checkbox">
                    <input type="checkbox" id="category-3" />
                    <label htmlFor="category-3">
                      <span></span>
                      Cameras
                      <small>(1450)</small>
                    </label>
                  </div>

                  <div className="input-checkbox">
                    <input type="checkbox" id="category-4" />
                    <label htmlFor="category-4">
                      <span></span>
                      Accessories
                      <small>(578)</small>
                    </label>
                  </div>

                  <div className="input-checkbox">
                    <input type="checkbox" id="category-5" />
                    <label htmlFor="category-5">
                      <span></span>
                      Laptops
                      <small>(120)</small>
                    </label>
                  </div>

                  <div className="input-checkbox">
                    <input type="checkbox" id="category-6" />
                    <label htmlFor="category-6">
                      <span></span>
                      Smartphones
                      <small>(740)</small>
                    </label>
                  </div>
                </div>
              </div> */}
              {/* <!-- /aside Widget --> */}

              {/* <!-- aside Widget --> */}
              <div className="aside">
                <h3 className="aside-title">Recent Post</h3>
                <PopularPostBlog />
              </div>
              {/* <!-- /aside Widget --> */}
            </div>
            {/* <!-- /ASIDE --> */}

            {/* <!-- STORE --> */}
            <div id="store" className="col-md-9">
            { showResults &&   (
              <div className="row">
                {blog.map((item, id) => (
                  <div className="col-md-4 col-sm-6  blg" key={id}>
                    <div className="card">

                      <img
                        src={item.blog_image.original}
                        className="card-img-top "
                        alt="..."
                      />

                      <div className=" caption card-body    text-center text-center mx-3 mx-height">
                        {/* <!-- post meta --> */}
                        <ul className="list-inline justify-content-between d-flex mb-3">
                          {/* <!-- post date --> */}
                          <li className="list-inline-item mr-3 ml-0">
                            <i
                              className="fa-solid fa-calendar-days"
                              style={{ marginRight: "4px" }}
                            ></i>
                            {item.created_at}
                          </li>
                        </ul>
                        <a>
                          <h4 className="card-title">
                            {" "}
                            {item.blog_title.slice(0, 50)}
                          </h4>
                        </a>
                        <p className="card-text">
                          {item.blog_description.slice(0, 100)}
                        </p>
                        <Link to={`/Blogsingle/${item.slug}`}>
                          {" "}
                          <button className="blog-btn">
                            <i className="fa-solid fa-angles-right"></i>{" "}
                            <i className="fa-solid fa-angles-right"></i> Read
                            More
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                ))}

             
              </div>)}

       
			
              {/* <!-- store bottom filter --> */}
              <div className="store-filter clearfix">
                <span className="store-qty">
                  {total !== undefined
                    ? `Showing ${Math.min(
                        (page - 1) * 20 + 1,
                        total
                      )}-${Math.min(page * 20, total)} Blogs`
                    : "Loading..."}
                </span>

                {/* <span className="store-qty">
                  {total !== undefined
                    ? `Showing ${Math.min(
                        (page - 1) * 24 + 1,
                        total
                      )}-${Math.min(
                        page * 24,
                        total
                      )} of ${total} Blogs in ${total} pages`
                    : "Loading..."}
                </span> */}

                <div className="store-pagination">
                  <button
                    disabled={page <= 1}
                    onClick={handlePrevious}
                    className="btn"
                  >
                    &laquo;
                  </button>

                  {[...Array(Math.min(4, totalPages)).keys()].map(
                    (pageNumber) => (
                      <button
                        key={pageNumber + 1}
                        disabled={pageNumber + 1 === page}
                        onClick={() => handlePageChange(pageNumber + 1)}
                        className="btn "
                      >
                        {pageNumber + 1}
                      </button>
                    )
                  )}

                  <button
                    disabled={page >= totalPages}
                    onClick={handleNext}
                    className="btn"
                  >
                    &raquo;
                  </button>
                </div>
              </div>
              {/* <!-- /store bottom filter --> */}

              {/* <!-- /store bottom filter --> */}
            </div>
            {/* <!-- /STORE --> */}
          </div>
          {/* <!-- /row --> */}
        </div>
        {/* <!-- /container --> */}
      </div>
      {/* <!-- /SECTION --> */}

      {/* <!-- SECTION --> */}
      <div className="section">
        {/* <!-- container --> */}
        <div className="container">
          {/* <!-- row --> */}
          <div className="row"></div>
          {/* <!-- /row --> */}
        </div>
        {/* <!-- /container --> */}
      </div>
      {/* <!-- /SECTION --> */}
    </>
  );
}

import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

export default function HomeBlog() {
  const [blog, setBlog] = useState([]);

  useEffect(() => {
    axios
      .get(
        "https://nepalautotrader.com/api/blogs?limit=4&orderBy=created_at DESC"
      )
      .then((res) => {
        console.log(res);
        setBlog(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <>
      
        {blog.map((item, id) => (
          <div className="col-md-3" key={id}>
            <div className="card">
              <img
                src={item.blog_image.original}
                className="card-img-top "
                alt="..."
              />
              <div className=" caption card-body    text-center text-center mx-3 mx-height">
                {/* <!-- post meta --> */}
                <ul className="list-inline justify-content-between d-flex mb-3">
                  {/* <!-- post date --> */}
                  <li className="list-inline-item mr-3 ml-0">
                    <i
                      className="fa-solid fa-calendar-days"
                      style={{ marginRight: "4px" }}
                    ></i>
                    {item.created_at}
                  </li>
                </ul>
                <a href="/">
                  <h4 className="card-title">
                    {" "}
                    {item.blog_title.slice(0, 50)}
                  </h4>
                </a>
                <p className="card-text">
                  {item.blog_description.slice(0, 100)}
                </p>
                <Link to={`/Blogsingle/${item.slug}`}>
                  {" "}
                  <button className="blog-btn">
                    <i className="fa-solid fa-angles-right"></i>{" "}
                    <i className="fa-solid fa-angles-right"></i> Read More
                  </button>
                </Link>
              </div>
            </div>
          </div>
        ))}
     
    </>
  );
}

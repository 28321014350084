import React, { useState } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
const apUrl = "https://jsonplaceholder.typicode.com/posts";

export default function Footer() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [successMessage, setSuccessMessage] = useState("");
  const [error, setError] = useState(null);
  const onSubmit = async (data) => {
    try {
      const response = await axios.post(apUrl, data);
      console.log("Form submission successful:", response.data);
      setSuccessMessage("Thankyou for your Subscription !!");
    } catch (error) {
      console.error("Form submission error:", error);
      setError("An error occurred while sending data");
    }
  };
  const closeSuccessMessage = () => {
    setSuccessMessage(""); // Clears the success message
  };
  return (
    <>
      {/* <!-- NEWSLETTER --> */}
      <div id="newsletter" className="section">
        {/* <!-- container --> */}
        <div className="container">
          {/* <!-- row --> */}

          <div className="row">
            <div className="col-md-12">
              <div className="newsletter">
                <p>
                  Sign Up for the <strong>NEWSLETTER</strong>
                </p>
                {successMessage && (
                  <div
                    className="alert alert-success alert-dismissible"
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    {successMessage}
                    <button
                      type="button"
                      className="btn-close text-right"
                      style={{
                        justifyContent: " end",
                        border: "none",
                        background: "none",
                      }}
                      onClick={closeSuccessMessage}
                    >
                      <i class="fa-solid fa-x"></i>
                    </button>
                  </div>
                )}
                <form onSubmit={handleSubmit(onSubmit)}>
                  <input
                    className="input"
                    type="email"
                    placeholder="Enter Your Email"
                    {...register("email", { required: true })}
                  />

                  <button className="newsletter-btn">
                    <i className="fa fa-envelope"></i> Subscribe
                  </button>
                  {errors.email && (
                    <span className="invalid-feedback">Email is required.</span>
                  )}
                </form>
                <ul className="newsletter-follow">
                  <li>
                    <a href="/">
                      <i className="fa-brands fa-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a href="/">
                      <i className="fa-brands fa-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a href="/">
                      <i className="fa-brands fa-instagram"></i>
                    </a>
                  </li>
                  <li>
                    <a href="/">
                      <i className="fa-brands fa-pinterest"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {/* <!-- /row --> */}
        </div>
        {/* <!-- /container --> */}
      </div>
      {/* <!-- /NEWSLETTER --> */}

      <footer id="footer">
        {/* <!-- top footer --> */}
        <div className="section">
          {/* <!-- container --> */}
          <div className="container">
            {/* <!-- row --> */}
            <div className="row">
              <div className="col-md-4 col-xs-6">
                <div className="footer">
                  <h3 className="footer-title">About Us</h3>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                    sed do eiusmod tempor incididunt ut.
                  </p>
                  <ul className="footer-links">
                    <li>
                      <a href="/">
                        <i className="fa fa-map-marker"></i>1734 Stonecoal Road
                      </a>
                    </li>
                    <li>
                      <a href="/">
                        <i className="fa fa-phone"></i>+021-95-51-84
                      </a>
                    </li>
                    <li>
                      <a href="/">
                        <i className="fa fa-envelope-o"></i>email@email.com
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-md-4 col-xs-6">
                <div className="footer">
                  <h3 className="footer-title">Categories</h3>
                  <ul className="footer-links">
                    {/* <li>
                      <a href="/">Hot deals</a>
                    </li> */}
                    <li>
                      <a href="/">Cars</a>
                    </li>
                    <li>
                      <a href="/">Bikes</a>
                    </li>
                    <li>
                      <a href="/">Scooters</a>
                    </li>
                    <li>
                      <a href="/">Accessories</a>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="clearfix visible-xs"></div>

              <div className="col-md-4 col-xs-6">
                <div className="footer">
                  <h3 className="footer-title">Information</h3>
                  <ul className="footer-links">
                    <li>
                      <a href="About.html">About Us</a>
                    </li>
                    <li>
                      <a href="checkout.html">Contact Us</a>
                    </li>
                    <li>
                      <a href="/">Privacy Policy</a>
                    </li>
                    <li>
                      <a href="/">Orders and Returns</a>
                    </li>
                    <li>
                      <a href="/">Terms & Conditions</a>
                    </li>
                  </ul>
                </div>
              </div>

              {/* <div className="col-md-3 col-xs-6">
                <div className="footer">
                  <h3 className="footer-title">Service</h3>
                  <ul className="footer-links">
                    <li>
                      <a href="/">My Account</a>
                    </li>
                    <li>
                      <a href="/">View Cart</a>
                    </li>
                    <li>
                      <a href="/">Wishlist</a>
                    </li>
                    <li>
                      <a href="/">Track My Order</a>
                    </li>
                    <li>
                      <a href="/">Help</a>
                    </li>
                  </ul>
                </div>
              </div> */}
            </div>
            {/* <!-- /row --> */}
          </div>
          {/* <!-- /container --> */}
        </div>
        {/* <!-- /top footer --> */}

        {/* <!-- bottom footer --> */}
        <div id="bottom-footer" className="section">
          
          <div className="container">
            {/* <!-- row --> */}
            <div className="row">
              <div className="col-md-12 text-center">
                {/* <ul className="footer-payments">
                  <li>
                    <a href="/">
                      <i className="fa fa-cc-visa"></i>
                    </a>
                  </li>
                  <li>
                    <a href="/">
                      <i className="fa fa-credit-card"></i>
                    </a>
                  </li>
                  <li>
                    <a href="/">
                      <i className="fa fa-cc-paypal"></i>
                    </a>
                  </li>
                  <li>
                    <a href="/">
                      <i className="fa fa-cc-mastercard"></i>
                    </a>
                  </li>
                  <li>
                    <a href="/">
                      <i className="fa fa-cc-discover"></i>
                    </a>
                  </li>
                  <li>
                    <a href="/">
                      <i className="fa fa-cc-amex"></i>
                    </a>
                  </li>
                </ul> */}
                <span className="copyright">
                  {/* <!-- Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. --> */}
                  Copyright &copy;
                  <script>document.write(new Date().getFullYear());</script>
                  All rights reserved | This template is made with {" "}
                  <i class="fa-solid fa-heart"></i> by
                  <a href=" https://nextmorse.com/" target="_blank">
                    Nextmorse
                  </a>
                  {/* <!-- Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. --> */}
                </span>
              </div>
            </div>
            {/* <!-- /row --> */}
          </div>
          {/* <!-- /container --> */}
        </div>
        {/* <!-- /bottom footer --> */}
      </footer>
    </>
  );
}

import React, { useState, useEffect } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
// import { Link } from "react-router-dom";
const apUrl = "https://api.autosansar.com/api/accessorybooking";

export default function AccessoriesBooking({ singleAccessory }) {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const [successMessage, setSuccessMessage] = useState("");
  const [error, setError] = useState(null);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);

  useEffect(() => {
    if (singleAccessory) {
      // Use setValue to set the value of "vehicle_id" in the form
      setValue("accessory_id", singleAccessory.id);
      setValue("price", singleAccessory.price);
    }
  }, [singleAccessory, setValue]);

  const onSubmit = async (data) => {
    try {
      // Include the "vehicle_id" in the form data
      data.accessory_id = singleAccessory.id;
      data.price = singleAccessory.price;

      const response = await axios.post(apUrl, data);

      console.log("Form submission successful:", response.data);
      setSuccessMessage(
        "We appreciate your reservation and will contact you shortly."
      );
    } catch (error) {
      console.error("Form submission error:", error);
      if (error.response) {
        console.error(
          "Server responded with an error status:",
          error.response.status
        );
        console.error("Error response data:", error.response.data);
      } else if (error.request) {
        console.error("No response received from the server");
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error("Error during request setup:", error.message);
      }
      setError("An error occurred while sending data");
    }
  };

  const closeSuccessMessage = () => {
    setSuccessMessage(""); // Clears the success message
  };

  const handleCheckboxChange = (e) => {
    setIsCheckboxChecked(e.target.checked);
  };

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          {/* <!-- Billing Details --> */}
          <div className="billing-details">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="col-md-7">
                <div className="section-title">
                  <h3 className="title">Fill Your Info</h3>
                </div>
                <div className="caption">
                  {successMessage && (
                    <div
                      className="alert alert-success alert-dismissible"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      {successMessage}
                      <button
                        type="button"
                        className="btn-close text-right"
                        style={{
                          justifyContent: " end",
                          border: "none",
                          background: "none",
                        }}
                        onClick={closeSuccessMessage}
                      >
                        <i class="fa-solid fa-x"></i>
                      </button>
                    </div>
                  )}
                  {error && <div className="alert alert-danger">{error}</div>}
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group mb-3">
                        <input type="hidden" {...register("vehicle_id")} />
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="col-md-6">
                        <div className="form-group mb-3">
                          <input
                            type="text"
                            className="form-control theme-light"
                            placeholder="First name"
                            {...register("firstname", { required: true })}
                          />
                          {errors.firstname &&
                            errors.firstname.type === "required" && (
                              <span className="invalid-feedback">
                                Name is required
                              </span>
                            )}
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group mb-3">
                          <input
                            type="text"
                            className="form-control theme-light"
                            placeholder="last name"
                            {...register("lastname", { required: true })}
                          />
                          {errors.lastname &&
                            errors.lastname.type === "required" && (
                              <span className="invalid-feedback">
                                lastname is required
                              </span>
                            )}
                        </div>
                      </div>
                    </div>

                    {/* End .col-6 */}
                    <div className="col-md-12">
                      <div className="form-group mb-3">
                        <input
                          type="Email"
                          className="form-control theme-light"
                          placeholder="Email"
                          {...register("email", { required: true })}
                        />
                        {errors.email && (
                          <span className="invalid-feedback">
                            Email is required.
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="form-group mb-3">
                        <input
                          type="number"
                          className="form-control theme-light"
                          placeholder="Number"
                          {...register("phone_no", { required: true })}
                        />
                        {errors.phone_no && (
                          <span className="invalid-feedback">
                            Number is required.
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="form-group mb-3">
                        <input
                          type="text"
                          className="form-control theme-light"
                          placeholder="Subject"
                          {...register("subject", { required: true })}
                        />
                        {errors.subject && (
                          <span className="invalid-feedback">
                            Subject is required.
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="form-group mb-3">
                        <input
                          type="text"
                          className="form-control theme-light"
                          placeholder="Address"
                          {...register("address", { required: true })}
                        />
                        {errors.address && (
                          <span className="invalid-feedback">
                            Subject is required.
                          </span>
                        )}
                      </div>
                    </div>
                    {/* End .col-12 */}

                    <div className="col-md-12">
                      <div className="form-group mb-3">
                        <textarea
                          rows="4"
                          className="form-control theme-light"
                          placeholder="Message"
                          {...register("message", { required: true })}
                        ></textarea>
                        {errors.message && (
                          <span className="invalid-feedback">
                            Comment is required.
                          </span>
                        )}
                      </div>
                    </div>
                    {/* End .col-12 */}

                    {/* End .col-12 */}
                  </div>

                  {/* ... End of your form ... */}
                </div>
              </div>

              <div className="col-md-5 order-details">
                <div className="section-title text-center">
                  <h3 className="title">Your Order</h3>
                </div>

                <div className="order-summary">
                  <div className="row">
                    <div className="product-img text-center">
                      <img
                        src="./img/product01.png"
                        className="p-image"
                        alt=""
                        style={{ width: "50%" }}
                      />
                    </div>
                    <h2 className="product-name px-3">
                      {singleAccessory.name}
                      {"  "}
                    </h2>
                    <div className="col-md-6">
                      <ul className="list-unstyled py-5 ">
                        <li style={{ margin: "3px" }}>
                          <i className="fa-solid fa-car px-3 "></i> Category :{" "}
                          {singleAccessory.accessorycategory?.name}
                        </li>
                        <li style={{ margin: "3px" }}>
                          <i className="fa-solid fa-location-crosshairs px-3"></i>{" "}
                          Location : {singleAccessory.location}
                        </li>

                        <li style={{ margin: "3px" }}>
                          <i className="fa-solid fa-user  px-3"></i> Status :{" "}
                          {singleAccessory.status}
                        </li>
                      </ul>
                    </div>
                    <div className="col-md-6">
                      <ul className="list-unstyled py-5 ">
                        <li style={{ margin: "3px" }}>
                          <i className="fa-solid fa-thumbtack px-3"></i>{" "}
                          Warranty : {singleAccessory.warranty}
                        </li>

                        <li style={{ margin: "3px" }}>
                          <i className="fa-solid fa-calendar px-3"></i>{" "}
                          Availability : {singleAccessory.availability}
                        </li>
                        <li style={{ margin: "3px" }}>
                          <i className="fa-solid fa-user  px-3"></i> Type :{" "}
                          {singleAccessory.delivery}
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="order-col">
                    <div>
                      <strong>Price</strong>
                    </div>
                    <div>
                      <strong className="order-total">
                        {singleAccessory.price}
                      </strong>
                    </div>
                  </div>{" "}
                </div>

                <div className="input-checkbox">
                  <input
                    type="checkbox"
                    id="terms"
                    onChange={handleCheckboxChange}
                    checked={isCheckboxChecked}
                  />
                  <label htmlFor="terms">
                    <span></span>
                    I've read and accept the <a href="/">terms & conditions</a>
                  </label>
                </div>
                {/* <a
                  href="/"
                  className={`primary-btn order-submit ${
                    !isCheckboxChecked ? "disabled" : ""
                  }`}
                  onClick={(e) => {
                    if (!isCheckboxChecked) {
                      e.preventDefault();
                      // Optionally show a message that terms are not accepted
                    }
                  }}
                >
                  Book for Now
                </a> */}
                <div className="col-12">
                  <div className="btn-bar">
                    <button
                      className={`primary-btn order-submit ${
                        !isCheckboxChecked ? "disabled" : ""
                      }`}
                      onClick={(e) => {
                        if (!isCheckboxChecked) {
                          e.preventDefault();
                          // Optionally show a message that terms are not accepted
                        }
                      }}
                    >
                       Book for Now
                    </button>
                  </div>
                </div>
              </div>
            </form>
            {/* <!-- /Billing Details --> */}
          </div>
        </div>

        {/* <!-- Order Details --> */}

        {/* <!-- /Order Details --> */}
      </div>
    </>
  );
}

import React, { useState, useEffect } from "react";

import axios from "axios";
import { Link } from "react-router-dom";
import InventorySidebar from "../components/InventorySidebar";

export default function Bike() {
  const [inventory, setInventory] = useState([]);

  useEffect(() => {
    axios
      .get("https://api.autosansar.com/api/vehicles")
      .then((response) => {
        if (response.status === 200) {
          const ProductType = response.data.data
            .filter(
              (product) =>
                product.type.name === "Bike" &&
                product.brand !== null &&
                product.brand.name !== null
            )
            .slice(0, 12);
          // const oldProducts = response.data.data.filter(product => product.condition.name === 'Old');
          setInventory(ProductType);
        } else {
          console.error(
            "Error fetching data from the API:",
            response.status,
            response.statusText
          );
        }
      })
      .catch((error) => {
        console.error("Error fetching data from the API:", error.message);
      });
  }, []);
  return (
    <>
      <div id="breadcrumb" className="section">
        {/* <!-- container --> */}
        <div className="container">
          {/* <!-- row --> */}
          <div className="row">
            <div className="col-md-12">
              <ul className="breadcrumb-tree">
                <li>
                  <Link href="index.html">Home</Link>
                </li>
               
                <li className="active">Bikes </li>
              </ul>
            </div>
          </div>
          {/* <!-- /row --> */}
        </div>
        {/* <!-- /container --> */}
      </div>
      {/* <!-- /BREADCRUMB --> */}

      {/* <!-- SECTION --> */}
      <div className="section">
        {/* <!-- container --> */}
        <div className="container">
          {/* <!-- row --> */}
          <div className="row">
            {/* <!-- ASIDE --> */}
            {/* <InventorySidebar /> */}
            {/* <!-- /ASIDE --> */}

            {/* <!-- STORE --> */}
            <div id="store" className="col-md-9">
          
         
              {/* <!-- store products --> */}
              <div className="row">
                {/* <!-- product --> */}

                {inventory.map((item, id) => (
                  <div className="col-md-4 col-xs-6" key={id}>
                    <Link to={`/Inventory_single/${item.id}`}>
                      <div className="product">
                        <div className="product-img">
                          {item.vehiclephotos.length > 0 && (
                            <img
                              src={`https://api.autosansar.com/${item.vehiclephotos[0].name}`}
                              className="p-image"
                              alt=""
                            />
                          )}

                          <div className="product-label">
                            {/* <span className="sale">-30%</span> */}
                            <span className="new">{item.condition.name}</span>
                          </div>
                        </div>
                        <div className="product-body">
                          <p className="product-category">{item.type.name}</p>
                          <h3 className="product-name">
                            <a href="/">
                              {" "}
                              {item.brand?.name}
                              {"  "} {item.car_model}
                            </a>
                          </h3>
                          <h4 className="product-price">
                            {item.price}{" "}
                            <del className="product-old-price">
                              {" "}
                              {item.oldprice}
                            </del>
                          </h4>

                          <div className="product-btns">
                            <button className="add-to-wishlist">
                              <i className="fa fa-heart-o"></i>
                              <span className="tooltipp">add to wishlist</span>
                            </button>
                            <button className="add-to-compare">
                              <i className="fa fa-exchange"></i>
                              <span className="tooltipp">add to compare</span>
                            </button>
                          </div>
                          <div className="product-rating"></div>
                          <div className="add-to-cart">
                            <Link to={`/Inventory_single/${item.id}`}>
                              {" "}
                              <button className="add-to-cart-btn">
                                <i className="fa fa-eye"></i> View now
                              </button>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                ))}
                {/* <!-- /product --> */}

                {/* <!-- product --> */}
                {/* <div className="col-md-4 col-xs-6">
                  <Link href="/inventory_single">
                    <div className="product">
                      <div className="product-img">
                        <img src="./img/car02.jpg" className="p-image" alt="" />
                        <div className="product-label">
                        
                        </div>
                      </div>
                      <div className="product-body">
                        <p className="product-category">Category</p>
                        <h3 className="product-name">
                          <Link href="/">product name goes here</Link>
                        </h3>
                        <h4 className="product-price">
                          $980.00{" "}
                          <del className="product-old-price">$990.00</del>
                        </h4>

                        <div className="product-btns">
                          <button className="add-to-wishlist">
                            <i className="fa fa-heart-o"></i>
                            <span className="tooltipp">add to wishlist</span>
                          </button>
                          <button className="add-to-compare">
                            <i className="fa fa-exchange"></i>
                            <span className="tooltipp">add to compare</span>
                          </button>
                        </div>
                        <div className="product-rating"></div>
                        <div className="add-to-cart">
                          <Link href="/inventory_single">
                            {" "}
                            <button className="add-to-cart-btn">
                              <i className="fa fa-eye"></i> View now
                            </button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div> */}
                {/* <!-- /product --> */}

                <div className="clearfix visible-sm visible-xs"></div>

                {/* <!-- /product --> */}
              </div>
              {/* <!-- /store products --> */}

              {/* <!-- store bottom filter --> */}

              {/* <!-- /store bottom filter --> */}
            </div>
            {/* <!-- /STORE --> */}
          </div>
          {/* <!-- /row --> */}
        </div>
        {/* <!-- /container --> */}
      </div>
    </>
  );
}

import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CarouselProduct from "./Carouselproduct";
import InventorysingleSingle from "./InventorysingleSingle";
import BookingForm from "./BookingForm";
// import { ImageAspectRatio } from "@mui/icons-material";

export default function Inventory_single() {
  const { id } = useParams();
  const [singleInventory, setSingleInventory] = useState({});

  useEffect(() => {
    if (id) {
      axios
        .get(`https://api.autosansar.com/api/vehicles/${id}`)
        .then((res) => {
          console.log(res);
          setSingleInventory(res.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [id]);

  return (
    <div>
      <>
        {/* <!-- BREADCRUMB --> */}
        <div id="breadcrumb" className="section">
          {/* <!-- container --> */}
          <div className="container">
            {/* <!-- row --> */}
            <div className="row">
              <div className="col-md-12">
                <ul className="breadcrumb-tree">
                  <li>
                    <a href="/">Home</a>
                  </li>
                  
                  <li>
                    <a href="/inventory">Inventory</a>
                  </li>
                  <li className="active">  {singleInventory.brand?.name}
                    {"  "} {singleInventory.car_model}</li>
                </ul>
              </div>
            </div>
            {/* <!-- /row --> */}
          </div>
          {/* <!-- /container --> */}
        </div>
        {/* <!-- /BREADCRUMB --> */}

        {/* <!-- SECTION --> */}
        <div className="section">
          {/* <!-- container --> */}
          <div className="container">
            {/* <!-- row --> */}
            <div className="row">
              {/* <!-- Product main img --> */}

              <CarouselProduct singleInventory={singleInventory} />
              {/* <!-- /Product thumb imgs --> */}

              {/* <!-- Product details --> */}
              <div className="col-md-5">
                <div className="product-details">
                  <h2 className="product-name">
                    {singleInventory.brand?.name}
                    {"  "} {singleInventory.car_model}
                  </h2>
                 
                  <div>
                    <h3 className="product-price">
                      ${singleInventory.price}{" "}
                      <del className="product-old-price">
                        {" "}
                        {singleInventory.oldprice}
                      </del>
                    </h3>
                    {/* <!-- <span className="product-available">In Stock</span> --> */}
                  </div>
                  <p>{singleInventory.description}</p>
                  <div className="row">
                    <div className="col-md-6">
                      <ul className="list-unstyled py-5 ">
                        <li style={{ margin: "3px" }}>
                          <i className="fa-solid fa-thumbtack px-3"></i> Model :{" "}
                          {singleInventory.car_model}
                        </li>
                        <li style={{ margin: "3px" }}>
                          <i className="fa-solid fa-location-dot px-3 "></i>{" "}
                          Condition : {singleInventory.condition?.name}
                        </li>
                        <li style={{ margin: "3px" }}>
                          <i className="fa-solid fa-brush  px-3"></i> Color: Red
                        </li>
                        <li style={{ margin: "3px" }}>
                          <i className="fa-solid fa-calendar px-3"></i> Year :{" "}
                          {singleInventory.year}
                        </li>
                        <li style={{ margin: "3px" }}>
                          <i className="fa-solid fa-user  px-3"></i> Type :{" "}
                          {singleInventory.type?.name}
                        </li>
                      </ul>
                    </div>
                    <div className="col-md-6">
                      <ul className="list-unstyled py-5 ">
                        <li style={{ margin: "3px" }}>
                          <i className="fa-solid fa-car px-3 "></i> Fuel :{" "}
                          {singleInventory.fuel?.name}
                        </li>
                        <li style={{ margin: "3px" }}>
                          <i className="fa-solid fa-location-crosshairs px-3"></i>{" "}
                          Location : {singleInventory.location?.name}
                        </li>
                        <li style={{ margin: "3px" }}>
                          <i className="fa-solid fa-gear px-3"></i> Gear :{" "}
                          {singleInventory.gear?.name}
                        </li>
                        <li style={{ margin: "3px" }}>
                          <i className="fa-solid fa-road px-3 "></i> Mileage :{" "}
                          {singleInventory.mileage}
                        </li>
                        <li style={{ margin: "3px" }}>
                          <i className="fa-solid fa-user  px-3"></i> seat :{" "}
                          {singleInventory.seat}
                        </li>
                      </ul>
                    </div>
                  </div>
                  {/* booking */}
                  <div className="add-to-cart">
                    {/* <!-- Large modal --> */}

                    <div className="add-to-cart">
                      <button
                        type="button"
                        className="add-to-cart-btn"
                        data-toggle="modal"
                        data-target=".bd-example-modal-lg"
                        style={{ marginTop: "15px" }}
                      >
                        <i className="fa fa-arrow-right-from-bracket"></i> Book
                        Now
                      </button>
                    </div>

                    <div
                      className="modal fade bd-example-modal-lg"
                      tabIndex="-1"
                      role="dialog"
                      aria-labelledby="myLargeModalLabel"
                      aria-hidden="true"
                    >
                      <div className="modal-dialog " style={{ width: "95%" }}>
                        <div className="modal-content">
                          <div className="container pt-2">
                            <div className="row mt-2">
                              <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                                style={{ margin: "1%" }}
                              >
                                <span aria-hidden="true">&times;</span>
                              </button>
                            </div>
                          </div>

                          <div className="section">
                            {/* <!-- container --> */}
                            <div className="container">
                              {/* <!-- row --> */}
                           
                              <BookingForm  singleInventory={singleInventory} />
                              {/* <!-- /row --> */}
                            </div>
                            {/* <!-- /container --> */}
                          </div>

                        
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* bookingEndS */}

                  <ul className="product-btns">
                    <li>
                      <a href="/">
                        <i
                          className="fa-regular fa-heart"
                          style={{ color: "black" }}
                        ></i>{" "}
                        add to wishlist
                      </a>
                    </li>
                  </ul>

                  <ul className="product-links">
                    <li>Share:</li>

                    <li>
                      <a href="/">
                        <i className="fa-brands fa-facebook"></i>
                      </a>
                    </li>
                    <li>
                      <a href="/">
                        <i className="fa-brands fa-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a href="/">
                        <i className="fa-brands fa-instagram"></i>
                      </a>
                    </li>
                    <li>
                      <a href="/">
                        <i className="fa-brands fa-pinterest"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/* <!-- /row --> */}

            {/* <!-- /container --> */}
          </div>
        </div>
        {/* <!-- /SECTION --> */}

        <div className="section">
          {/* <!-- container --> */}
          <InventorysingleSingle />
          {/* <!-- /container --> */}
        </div>
      </>
    </div>
  );
}

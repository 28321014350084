import React from 'react';

export default function About() {
  return (
    <>
       {/* <!-- BREADCRUMB --> */}
  <div id="breadcrumb" className="section">
    {/* <!-- container --> */}
    <div className="container">
      {/* <!-- row --> */}
      <div className="row">
        <div className="col-md-12">
          <h3 className="breadcrumb-header">Regular Page</h3>
          <ul className="breadcrumb-tree">
            <li><a href="/">Home</a></li>
            <li className="active">About</li>
          </ul>
        </div>
      </div>
      {/* <!-- /row --> */}
    </div>
    {/* <!-- /container --> */}
  </div>
  {/* <!-- /BREADCRUMB --> */}

  <div className="section">
    <div className="container">
      <div className="col-md-12">
        <div className="newsletter p-5">
          <p>Why Choose <strong>Us?</strong></p>

        </div>
      </div>


      <div className="row row-cols-1 row-cols-md-2 row-cols-lg-2   ">
        <div className="col-md-6  "style={{margin: "30px auto"}}>
          <div className="row row-cols-1 row-cols-sm-2 d-flex mt-4">
            <div className="col-md-6 pt-2">
              <div className="container  ">
                <img className="about-img" src="./img/imageo4.jpg" alt="..." />
              </div>
            </div>
            <div className="col-md-6 mt-4 ">
              <h3 className="colorh">Who <span>Are We</span></h3>
              <p>On the other hand, we denounce with righteous indignation and dislike men who are so beguiled and
                demoralized by the charms of pleasure of the moment.</p>
            </div>
          </div>
        </div>
        <div className="col-md-6  " style={{margin: "30px auto"}}>
          <div className="row row-cols-1 row-cols-sm-2 d-flex mt-4">
            <div className="col-md-6 pt-2">
              <div className="container  ">
                <img className="about-img" src="./img/imageo.jpg" alt="..." />
              </div>
            </div>
            <div className="col-md-6 mt-4 ">
              <h3 className="colorh">Our <span>Mission</span></h3>
              <p>On the other hand, we denounce with righteous indignation and dislike men who are so beguiled and
                demoralized by the charms of pleasure of the moment.</p>
            </div>
          </div>
        </div>




      </div>
    </div>

  </div>


  {/* <!-- /HOT DEAL SECTION --> */}

  {/* <!-- HOT DEAL SECTION --> */}
  <div id="hot-deal" className="section">
    {/* <!-- container --> */}
    <div className="container">
      {/* <!-- row --> */}
      <div className="row">
        <div className="col-md-12">
          <div className="hot-deal">
            <ul className="hot-deal-countdown">
              <li>
                <div>
                  <h3>02</h3>
                  <span>Days</span>
                </div>
              </li>
              <li>
                <div>
                  <h3>10</h3>
                  <span>Hours</span>
                </div>
              </li>
              <li>
                <div>
                  <h3>34</h3>
                  <span>Mins</span>
                </div>
              </li>
              <li>
                <div>
                  <h3>60</h3>
                  <span>Secs</span>
                </div>
              </li>
            </ul>
            <h2 className="text-uppercase">hot deal this week</h2>
            <p>New Collection Up to 50% OFF</p>
            <a className="primary-btn cta-btn" href="/">Book now</a>
          </div>
        </div>
      </div>
      {/* <!-- /row --> */}
    </div>
    {/* <!-- /container --> */}
  </div>
  {/* <!-- /HOT DEAL SECTION --> */}

  <div className="section">
    {/* <!-- container --> */}
    <div className="container">
      {/* <!-- row --> */}


      <div className="row no-gutters">
        <div className="col-md-6">
          <div className="heading-section heading-section-white ">
            <span className="subheading ">About us</span>
            <h2 className="">Welcome to AutoSansar</h2>
            <p style={{margin: "30px auto"}}>A small river named Duden flows by their place and supplies it with the
              necessary regelialia. It is a paradisematic country, in which roasted parts of sentences fly into
              your mouth. <br/> On her way she met a copy. The copy warned the Little Blind Text, that where it
              came from it would have been rewritten a thousand times and everything that was left from its origin
              would be the word "and" and the Little Blind Text should turn around and return to its own, safe
              country. A small river named Duden flows by their place and supplies it with the necessary
              regelialia. It is a paradisematic country, in which roasted parts of sentences fly into your mouth.
              A small river named Duden flows by their place and supplies it with the necessary
              regelialia. It is a paradisematic country, in which roasted parts of sentences fly into your mouth.
            </p>

            <a className="primary-btn cta-btn" href="/">Learn More</a>
          </div>
        </div>
        <div className="col-md-6 ">
          <div className="product-img">
            <img src="./img/product03.png" alt="" style={{width:" 80%"}} />

          </div>




        </div>
      </div>
      {/* <!-- /row --> */}
    </div>
    {/* <!-- /container --> */}
  </div>
    </>
  );
}

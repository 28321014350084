import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../slick.css";

const CarouselProduct = ({ singleInventory }) => {
  const [selectedItem, setSelectedItem] = useState(0);

  if (!singleInventory || !singleInventory.vehiclephotos || singleInventory.vehiclephotos.length === 0) {
    return (
      <div>
        {/* You can display a loading state or default content here */}
        Loading...
      </div>
    );
  }

  // Calculate the dynamic slidesToShow value
  // const slidesToShow = singleInventory.vehiclephotos.length > 3 ? 3 : singleInventory.vehiclephotos.length - 1;
  const slidesToShow = singleInventory.vehiclephotos.length === 1 ? 1 : Math.min(3, singleInventory.vehiclephotos.length - 1);

  const settings = {
    dots: true,
    slidesToShow: slidesToShow,
    slidesToScroll: 2,
    vertical: true,
    verticalSwiping: true,
    nav: true,
    navText: [
      '<button className="slicks-prev slick-arrow" aria-label="Previous" type="button" style={{display:" block"}}>Previous</button>',
      '<button className="slick-next slick-arrow" aria-label="Next" type="button"  style={{display:" block", backgroundColor:"red"}}><i class="fa-solid fa-circle-arrow-down"></i></button>',
    ],
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: 3,
          nav: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          nav: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          nav: true,
        },
      },
    ],
  };

  const handleItemClick = (index) => {
    setSelectedItem(index);
  };

  return (
    <>
      {/* Column 1 */}
      <div className="col-md-5 col-md-push-2">
        <div id="">
          {selectedItem !== null && (
            <img
              src={`https://api.autosansar.com/${singleInventory.vehiclephotos[selectedItem].name}`}
              alt={`Product ${selectedItem + 1}`}
              style={{ width: "100%", height: "80%", borderRadius:"20px"}}
            />
          )}
        </div>
      </div>

      {/* Column 2 */}
      <div className="col-md-2 col-md-pull-5">
        <div id="product-imgs">
          <Slider {...settings}>
            {singleInventory.vehiclephotos.map((imageName, index) => (
              <div
                key={index}
                className={`item ${selectedItem === index ? "selected" : ""}`}
                onClick={() => handleItemClick(index)}
              >
                <div className="product-preview">
                  <img
                    src={`https://api.autosansar.com/${imageName.name}`}
                    alt={`Product ${index + 1}`}
              
                  />
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </>
  );
};

export default CarouselProduct;

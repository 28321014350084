import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import PopularPostBlog from "./Listed/PopularPostBlog";

export default function Blogsingle() {
  const { slug } = useParams();
  const [singlePost, setSinglePost] = useState([]);

  useEffect(() => {
    if (slug) {
      axios
        .get(`http://nepalautotrader.com/api/blogs/${slug}`)
        .then((res) => {
          console.log(res.data);
          setSinglePost(res.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [slug]);

  useEffect(() => {
    // Ensure that singlePost.created_at is a string
    console.log("created_at:", singlePost.created_at);
  }, [singlePost]);

  // const formattedDate = new Date(singlePost.created_at).toLocaleDateString();

  //    const getPost =()=>{
  //     fetch ( )
  //     .then((res) =>{
  //       if(res.ok){
  //         return res.json();

  //       }
  //       else{
  //         console.log("res error");

  //       }
  //     })
  //     .then(res => {
  //       setSinglePost(res.data);
  //       console.log(res );

  //       })

  //     .catch ((err)=>console.log(err))
  //    }

  //    useEffect (()=>{
  //     getPost();

  //    },[]);

  return (
    <>
      {/* <!-- BREADCRUMB --> */}
      <div id="breadcrumb" className="section">
        {/* <!-- container --> */}
        <div className="container">
          {/* <!-- row --> */}
          <div className="row">
            <div className="col-md-12">
 
              <ul className="breadcrumb-tree">
                <li>
                  <a href="/">Home</a>
                </li>
                <li>
                  <a href="/Blog">Blog</a>
                </li>
                <li className="active">{singlePost.blog_title}</li>
              </ul>
            </div>
          </div>
          {/* <!-- /row --> */}
        </div>
        {/* <!-- /container --> */}
      </div>
      {/* <!-- /BREADCRUMB --> */}

      {/* <!-- SECTION --> */}
      <div className="section">
        {/* <!-- container --> */}
        <div className="container">
          {/* <!-- row --> */}
          <div className="row">
            {/* <!-- ASIDE --> */}
            <div id="aside" className="col-md-3">
              {/* <!-- aside Widget --> */}

          

              {/* <!-- SEARCH BAR --> */}

              {/* <!-- /SEARCH BAR --> */}
              {/* <div className="aside">
                <h3 className="aside-title">Categories</h3>
                <div className="checkbox-filter">
                  <div className="input-checkbox">
                    <input type="checkbox" id="category-1" />
                    <label htmlFor="category-1">
                      <span></span>
                      Laptops
                      <small>(120)</small>
                    </label>
                  </div>

                  <div className="input-checkbox">
                    <input type="checkbox" id="category-2" />
                    <label htmlFor="category-2">
                      <span></span>
                      Smartphones
                      <small>(740)</small>
                    </label>
                  </div>

                  <div className="input-checkbox">
                    <input type="checkbox" id="category-3" />
                    <label htmlFor="category-3">
                      <span></span>
                      Cameras
                      <small>(1450)</small>
                    </label>
                  </div>

                  <div className="input-checkbox">
                    <input type="checkbox" id="category-4" />
                    <label htmlFor="category-4">
                      <span></span>
                      Accessories
                      <small>(578)</small>
                    </label>
                  </div>

                  <div className="input-checkbox">
                    <input type="checkbox" id="category-5" />
                    <label htmlFor="category-5">
                      <span></span>
                      Laptops
                      <small>(120)</small>
                    </label>
                  </div>

                  <div className="input-checkbox">
                    <input type="checkbox" id="category-6" />
                    <label htmlFor="category-6">
                      <span></span>
                      Smartphones
                      <small>(740)</small>
                    </label>
                  </div>
                </div>
              </div> */}
              {/* <!-- /aside Widget --> */}

              {/* <!-- aside Widget --> */}
              <div className="aside">
                <h3 className="aside-title">Popular Post</h3>

                <PopularPostBlog />
              </div>
              {/* <!-- /aside Widget --> */}
            </div>
            {/* <!-- /ASIDE --> */}
            <div className="col-md-9">
              <div className="">
                <img
                  src={singlePost.blog_image?.original}
                  className="card-img "
                  alt="..."
                />
                <div className="" style={{ margin: "15px" }}>
                  {/* <!-- post meta --> */}
                  <ul className="list-inline justify-content-between d-flex mb-3">
                    <li className="list-inline-item mr-3 ml-0">
                      {singlePost.created_at && singlePost.created_at > 0 && (
                          
                        <span
                          className="fa-solid fa-calendar-days"
                          style={{ marginRight: "4px" }}
                        >
                           {singlePost.created_at}
                        </span>
                      )}
                    </li>
                  </ul>

                  <a href="/">
                    <h4 className="card-title">{singlePost.blog_title} </h4>
                  </a>
                  <p
                    className="card-text"
                    dangerouslySetInnerHTML={{
                      __html: singlePost.blog_description,
                    }}
                  ></p>

                  <div className="product-details">
                    <ul className="product-links">
                      <li>Share:</li>

                      <li>
                        <a href="/">
                          <i className="fa-brands fa-facebook"></i>
                        </a>
                      </li>
                      <li>
                        <a href="/">
                          <i className="fa-brands fa-twitter"></i>
                        </a>
                      </li>
                      <li>
                        <a href="/">
                          <i className="fa-brands fa-instagram"></i>
                        </a>
                      </li>
                      <li>
                        <a href="/">
                          <i className="fa-brands fa-pinterest"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            {/* <!-- STORE --> */}

            {/* <!-- /STORE --> */}
          </div>
          {/* <!-- /row --> */}
        </div>
        {/* <!-- /container --> */}
      </div>
      {/* <!-- /SECTION --> */}
    </>
  );
}

import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import Search from "./search/Search";

function Navbar( ) {
 

 


  return (
    <>
      {/* <!-- HEADER --> */}
      <header>
        {/* <!-- TOP HEADER --> */}
        <div id="top-header" className="bg-white text-dark">
          <div className="container">
            <ul className="header-links pull-left">
              <li>
                <a href="/">
                  <i className="fa fa-phone"></i> +021-95-51-84
                </a>
              </li>
              <li>
                <a href="/">
                  <i className="fa fa-envelope-o"></i> email@email.com
                </a>
              </li>
              <li>
                <a href="/">
                  <i className="fa fa-map-marker"></i> 1734 Stonecoal Road
                </a>
              </li>
            </ul>
            <ul className="header-links pull-right">
              <li>
                <a href="/">
                  <i className="fa fa-user-o"></i> My Account
                </a>
              </li>
            </ul>
          </div>
        </div>
        {/* <!-- /TOP HEADER --> */}

        {/* <!-- MAIN HEADER --> */}
        <div id="header ">
          {/* <!-- container --> */}
          <div className="container bg-white text-dark">
            {/* <!-- row --> */}
            <div className="row">
              {/* <!-- LOGO --> */}
              <div className="col-md-3">
                <div className="header-logo">
                  <a href="/" className="logo">
                    <img
                      src="./img/logo.png"
                      alt=""
                      style={{ width: "80% " }}
                    />
                  </a>
                </div>
              </div>
              {/* <!-- /LOGO --> */}

              {/* <!-- SEARCH BAR --> */}
              <div className="col-md-6">
                <Search
                 
                 
                />
                {/* <div className="header-search">
                  <form onSubmit={handleSubmit}>
                    <select
                      className="input-select"
                      onChange={(e) => onCategoryChange(e.target.value)}
                    >
                      <option value="0">Inventory</option>
                      <option value="1">Accessories</option>
                    </select>
                    <input
                      className="input"
                      type="text"
                      name="search"
                      placeholder="Search here"
                      value={query}
                      onChange={(e) => handleChange(e.target.value)}
                    />
                    <button className="search-btn" type="submit">
                      Search
                    </button>
                  </form>
                </div> */}
                {/* <Search/> */}
              </div>
              {/* <!-- /SEARCH BAR --> */}

              {/* <!-- ACCOUNT --> */}
              <div className="col-md-3 clearfix text-dark">
                <div className="header-ctn">
                  {/* <!-- Wishlist --> */}
                  <div>
                    <a href="/">
                      <i
                        className="fa-regular fa-heart"
                        style={{ color: "black" }}
                      ></i>
                      <span style={{ color: "#39464d" }}>Your Wishlist</span>

                      <div className="qty">2</div>
                    </a>
                  </div>
                  {/* <!-- /Wishlist --> */}

                  {/* <!-- Menu Toogle --> */}
                  <div className="menu-toggle">
                    <a href="/">
                      <i className="fa fa-bars"></i>
                      <span>Menu</span>
                    </a>
                  </div>
                  {/* <!-- /Menu Toogle --> */}
                </div>
              </div>
              {/* <!-- /ACCOUNT --> */}
            </div>
            {/* <!-- row --> */}
          </div>
          {/* <!-- container --> */}
        </div>
        {/* <!-- /MAIN HEADER --> */}
      </header>
      {/* <!-- /HEADER --> */}

      {/* <!-- NAVIGATION --> */}
      <nav id="navigation">
        {/* <!-- container --> */}
        <div className="container">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
            aria-expanded="false"
          >
            <span className="sr-only">Toggle navigation</span>
            <i className="fa-solid fa-bars"></i>
          </button>

          <div
            className="collapse navbar-collapse"
            id="bs-example-navbar-collapse-1"
          >
            <ul className="main-nav nav navbar-nav">
              <li>
                <NavLink to="/" exact activeClassName="active">
                  Home
                </NavLink>
              </li>
              <li>
                <NavLink to="/about" activeClassName="active">
                  About
                </NavLink>
              </li>
              <li>
                <NavLink to="/inventory" activeClassName="active">
                  Inventory
                </NavLink>
              </li>
              <li>
                <NavLink to="/Accessories" activeClassName="active">
                  Accessories
                </NavLink>
              </li>
              <li>
                <NavLink to="/blog" activeClassName="active">
                  Blog
                </NavLink>
              </li>
              <li>
                <NavLink to="/contact" activeClassName="active">
                  Contact
                </NavLink>
              </li>
            </ul>
          </div>

          {/* <div id="responsive-nav">
       
            <ul className="main-nav nav navbar-nav">
              <li className="active">
                <a href="/">Home</a>
              </li>
              <li className="">
                <a href="/about">About</a>
              </li>
              <li>
                <a href="/inventory">Inventory</a>
              </li>
              <li>
                <a href="/blog">Blog</a>
              </li>
              <li>
                <a href="/contact">Contact</a>
              </li>
            
            </ul>
          
          </div>
       */}
        </div>
        {/* <!-- /container --> */}
      </nav>

      {/* <!-- /NAVIGATION --> */}
    </>
  );
}
export default Navbar;

import React from "react";
import SlickHome from "./HOME/SlickHome";
import HomeBlog from "./HOME/HomeBlog";
import HomeNewInvent from "./HOME/HomeNewInvent";
import { Link } from "react-router-dom";


export default function Home() {

  // const handleButtonClick = () => {
  //   applyFilters({
  //     selectedBrands: ['Bike'],
  //     // Add more filters as needed
  //   });
  // };
  return (
    
    <>
      {/* <!-- SECTION top--> */}
      <div className="section">
        {/* <!-- container --> */}
        <div className="container">
          {/* <!-- row --> */}
          <div className="row">
            {/* <!-- shop --> */}
            <div className="col-md-3 col-xs-6">
              <div className="shop">
                <div className="shop-img">
                  <img src="./img/car1.png" alt="" />
                </div>
                <div className="shop-body">
                  <h3>
                    Car <br />
                  </h3>
                  
                  <a href="/Car" className="cta-btn">
                 Book Now <i className="fa fa-arrow-circle-right"></i>
                  </a>
                </div>
              </div>
            </div>
            {/* <!-- /shop --> */}

            {/* <!-- shop --> */}
            <div className="col-md-3 col-xs-6">
              <div className="shop">
                <div className="shop-img">
                  <img src="./img/car2.png" alt="" />
                </div>
                <div className="shop-body">
                  <h3>
                    Bike <br />
                  </h3>
                  <a href="/Bike" className="cta-btn">
                 Book Now <i className="fa fa-arrow-circle-right"></i>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-xs-6">
              <div className="shop">
                <div className="shop-img">
                  <img src="./img/car2.png" alt="" />
                </div>
                <div className="shop-body">
                  <h3>
                    Scooter <br />
                  </h3>
                  <a href="/Scooter" className="cta-btn">
                 Book Now <i className="fa fa-arrow-circle-right"></i>
                  </a>
                </div>
              </div>
            </div>

            <div className="col-md-3 col-xs-6">
              <div className="shop">
                <div className="shop-img">
                  <img src="./img/car2.png" alt="" />
                </div>
                <div className="shop-body">
                  <h3>
                    Accessories
                    <br />
                  </h3>
                  <a href="/Accessories" className="cta-btn">
                    Shop now <i className="fa fa-arrow-circle-right"></i>
                  </a>
                </div>
              </div>
            </div>
            {/* <!-- /shop --> */}
          </div>
          {/* <!-- /row --> */}
        </div>
        {/* <!-- /container --> */}
      </div>
      {/* <!-- /SECTION top --> */}

      {/* <!-- SECTION NEW PRODUCT --> */}
      <div className="section">
        {/* <!-- container --> */}Z
        <div className="container">
          {/* <!-- row --> */}
          <div className="row">
            {/* <!-- section title --> */}
            <div className="col-md-12">
            <div className="section-title"  style={{display: "flex", justifyContent: "space-between"}}>
                <h3 className="title" >Featured Products</h3>
                <Link to="/Inventory" >
                  <button className="allbtn">see all</button>
                </Link>
              </div>
            </div>
            {/* <!-- /section title --> */}

            {/* <!-- Products tab & slick --> */}
            <div className="col-md-12">
              <div className="row">
                <div className="products-tabs">
                  {/* <!-- tab --> */}
                  <div id="tab1" className="tab-pane active">
                    <SlickHome />
                  </div>
                  {/* <!-- /tab --> */}
                </div>
              </div>
            </div>
            {/* <!-- Products tab & slick --> */}
          </div>
          {/* <!-- /row --> */}
        </div>
        {/* <!-- /container --> */}
      </div>
      {/* <!-- /SECTION NEW PRODUCT--> */}

      {/* <!-- HOT DEAL SECTION --> */}
      <div id="hot-deal" className="section">
        {/* <!-- container --> */}
        <div className="container">
          {/* <!-- row --> */}
          <div className="row">
            <div className="col-md-12">
              <div className="hot-deal">
                <ul className="hot-deal-countdown">
                  <li>
                    <div>
                      <h3>02</h3>
                      <span>Days</span>
                    </div>
                  </li>
                  <li>
                    <div>
                      <h3>10</h3>
                      <span>Hours</span>
                    </div>
                  </li>
                  <li>
                    <div>
                      <h3>34</h3>
                      <span>Mins</span>
                    </div>
                  </li>
                  <li>
                    <div>
                      <h3>60</h3>
                      <span>Secs</span>
                    </div>
                  </li>
                </ul>
                <h2 className="text-uppercase">hot deal this week</h2>
                <p>New Collection Up to 50% OFF</p>
                <a className="primary-btn cta-btn" href="store.html">
                  Book now
                </a>
              </div>
            </div>
          </div>
          {/* <!-- /row --> */}
        </div>
        {/* <!-- /container --> */}
      </div>
      {/* <!-- /HOT DEAL SECTION --> */}

      {/* <!-- Section --> */}
      <div className="section">
        {/* <!-- container --> */}
        <div className="container">
          {/* <!-- row --> */}
          <div className="row">
            <div className="col-md-12">
            <div className="section-title"  style={{display: "flex", justifyContent: "space-between"}}>
                <h3 className="title" >New Products</h3>
                <Link to="/Inventory" >
                  <button className="allbtn">see all</button>
                </Link>
              </div>
            </div>

            <HomeNewInvent />
          </div>
          {/* <!-- /row --> */}
        </div>
        {/* <!-- /container --> */}
      </div>
      {/* <!-- /Section --> */}

      {/* <!-- Section --> */}
      <div className="section">
        {/* <!-- container --> */}
        <div className="container">
          {/* <!-- row --> */}
          <div className="row">
            <div className="col-md-12">
              <div className="section-title"  style={{display: "flex", justifyContent: "space-between"}}>
                <h3 className="title" > latest Blogs</h3>
                <Link to="/Blog" >
                  <button className="allbtn">see all</button>
                </Link>
              </div>
            </div>

            <HomeBlog />
          </div>
          {/* <!-- /row --> */}
        </div>
        {/* <!-- /container --> */}
      </div>
      {/* <!-- /Section --> */}
    </>
  );
}

import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import AccessoriesBooking from "./AccessoriesBooking";
import CarouselAccessory from "./CarouselAccessory";
// import { ImageAspectRatio } from "@mui/icons-material";

export default function AccessoriesSingle() {
  const { id } = useParams();
  const [singleAccessory, setSingleAccessory] = useState({});

  useEffect(() => {
    if (id) {
      axios
        .get(`https://api.autosansar.com/api/accessories/${id}`)
        .then((res) => {
          console.log(res);
          setSingleAccessory(res.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [id]);

  return (
    <div>
      <>
        {/* <!-- BREADCRUMB --> */}
        <div id="breadcrumb" className="section">
          {/* <!-- container --> */}
          <div className="container">
            {/* <!-- row --> */}
            <div className="row">
              <div className="col-md-12">
                <ul className="breadcrumb-tree">
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li>
                    <a href="/Accessories">Accessories</a>
                  </li>
                  <li className="active"> {singleAccessory.name}</li>
                </ul>
              </div>
            </div>
            {/* <!-- /row --> */}
          </div>
          {/* <!-- /container --> */}
        </div>
        {/* <!-- /BREADCRUMB --> */}

        {/* <!-- SECTION --> */}
        <div className="section">
          {/* <!-- container --> */}
          <div className="container">
            {/* <!-- row --> */}
            <div className="row">
              {/* <!-- Product main img --> */}

              <CarouselAccessory singleAccessory={singleAccessory} />
              {/* <!-- /Product thumb imgs --> */}

              {/* <!-- Product details --> */}
              <div className="col-md-5">
                <div className="product-details">
                  <h2 className="product-name">
                    {singleAccessory.name}
                    {"  "}
                  </h2>

                  <div>
                    <h3 className="product-price">
                      ${singleAccessory.price}{" "}
                      <del className="product-old-price">
                        {" "}
                        {/* {singleAccessory.oldprice} */}
                      </del>
                    </h3>
                    {/* <!-- <span className="product-available">In Stock</span> --> */}
                  </div>
                  <p>{singleAccessory.description}</p>
                  <div className="row">
                    <div className="col-md-6">
                      <ul className="list-unstyled py-5 ">
                        <li style={{ margin: "3px" }}>
                          <i className="fa-solid fa-thumbtack px-3"></i>{" "}
                          Warranty : {singleAccessory.warranty}
                        </li>

                        <li style={{ margin: "3px" }}>
                          <i className="fa-solid fa-calendar px-3"></i>{" "}
                          Availability : {singleAccessory.availability}
                        </li>
                        <li style={{ margin: "3px" }}>
                          <i className="fa-solid fa-user  px-3"></i> Type :{" "}
                          {singleAccessory.delivery}
                        </li>
                      </ul>
                    </div>
                    <div className="col-md-6">
                      <ul className="list-unstyled py-5 ">
                        <li style={{ margin: "3px" }}>
                          <i className="fa-solid fa-car px-3 "></i> Category :{" "}
                          {/* {singleAccessory.accessorycategory} */}
                        </li>
                        <li style={{ margin: "3px" }}>
                          <i className="fa-solid fa-location-crosshairs px-3"></i>{" "}
                          Location : {singleAccessory.location}
                        </li>

                        <li style={{ margin: "3px" }}>
                          <i className="fa-solid fa-user  px-3"></i> Status :{" "}
                          {singleAccessory.status}
                        </li>
                      </ul>
                    </div>
                  </div>
                  {/* booking */}
                  <div className="add-to-cart">
                    {/* <!-- Large modal --> */}

                    <div className="add-to-cart">
                      <button
                        type="button"
                        className="add-to-cart-btn"
                        data-toggle="modal"
                        data-target=".bd-example-modal-lg"
                        style={{ marginTop: "15px" }}
                      >
                        <i className="fa fa-arrow-right-from-bracket"></i> Book
                        Now
                      </button>
                    </div>

                    <div
                      className="modal fade bd-example-modal-lg"
                      tabIndex="-1"
                      role="dialog"
                      aria-labelledby="myLargeModalLabel"
                      aria-hidden="true"
                    >
                      <div className="modal-dialog " style={{ width: "95%" }}>
                        <div className="modal-content">
                          <div className="container pt-2">
                            <div className="row mt-2">
                              <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                                style={{ margin: "1%" }}
                              >
                                <span aria-hidden="true">&times;</span>
                              </button>
                            </div>
                          </div>

                          <div className="section">
                            {/* <!-- container --> */}
                            <div className="container">
                              {/* <!-- row --> */}
                              {/* <AccessoriesBooking/> */}
                              <AccessoriesBooking
                                singleAccessory={singleAccessory}
                              />
                              {/* <!-- /row --> */}
                            </div>
                            {/* <!-- /container --> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* bookingEndS */}

                  <ul className="product-btns">
                    <li>
                      <a href="/">
                        <i
                          className="fa-regular fa-heart"
                          style={{ color: "black" }}
                        ></i>{" "}
                        add to wishlist
                      </a>
                    </li>
                  </ul>

                  <ul className="product-links">
                    <li>Share:</li>

                    <li>
                      <a href="/">
                        <i className="fa-brands fa-facebook"></i>
                      </a>
                    </li>
                    <li>
                      <a href="/">
                        <i className="fa-brands fa-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a href="/">
                        <i className="fa-brands fa-instagram"></i>
                      </a>
                    </li>
                    <li>
                      <a href="/">
                        <i className="fa-brands fa-pinterest"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/* <!-- /row --> */}

            {/* <!-- /container --> */}
          </div>
        </div>
        {/* <!-- /SECTION --> */}

        <div className="section">
          {/* <!-- container --> */}
          {/* <InventorysingleSingle /> */}
          {/* <!-- /container --> */}
        </div>
      </>
    </div>
  );
}

import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Inventory_single from "./Inventory_single";

export default function InventorysingleSingle() {
  const [inventory, setInventory] = useState([]);
  useEffect(() => {
    axios
      .get(`https://api.autosansar.com/api/vehicles?&limit=4`)
      .then((res) => {
        console.log(res);
        setInventory(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <>
      <div className="container">
        {/* <!-- row --> */}
        <div className="row">
          <div className="col-md-12">
            <div className="section-title">
              <h3 className="title">Recently Added</h3>
            </div>
          </div>

          {/* <!-- product --> */}
          {inventory.map((item, id) => (
            <div className="col-md-3 col-xs-6" key={id}>
              <Link to={`/Inventory_single/${item.id}`}>
                <div className="product">
                  <div className="product-img">
                    {item.vehiclephotos.length > 0 && (
                      <img
                        src={`https://api.autosansar.com/${item.vehiclephotos[0].name}`}
                        className="p-image"
                        alt=""
                      />
                    )}

                    <div className="product-label">
                      {/* <span className="sale">-30%</span> */}
                      <span className="new">{item.condition.name}</span>
                    </div>
                  </div>
                  <div className="product-body">
                    <p className="product-category">{item.type.name}</p>
                    <h3 className="product-name">
                      <a href="/">
                        {" "}
                        {item.brand?.name}
                        {"  "} {item.car_model}
                      </a>
                    </h3>
                    <h4 className="product-price">
                      {item.price}{" "}
                      <del className="product-old-price"> {item.oldprice}</del>
                    </h4>

                    <div className="product-btns">
                      <button className="add-to-wishlist">
                        <i className="fa fa-heart-o"></i>
                        <span className="tooltipp">add to wishlist</span>
                      </button>
                      <button className="add-to-compare">
                        <i className="fa fa-exchange"></i>
                        <span className="tooltipp">add to compare</span>
                      </button>
                    </div>
                    <div className="product-rating"></div>
                    <div className="add-to-cart">
                      <Link to={`/Inventory_single/${item.id}`}>
                        {" "}
                        <button className="add-to-cart-btn">
                          <i className="fa fa-eye"></i> View now
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          ))}

          {/* <!-- /product -->

        <!-- product --> */}
          {/* <div className="col-md-3 col-xs-6">
                <a href="/inventory_single">
                  <div className="product">
                    <div className="product-img">
                      <img src="./img/car01.jpg" className="p-image" alt="" />
                      <div className="product-label">
                        <span className="sale">-30%</span>
                        <span className="new">NEW</span>
                      </div>
                    </div>
                    <div className="product-body">
                      <p className="product-category">Category</p>
                      <h3 className="product-name">
                        <a href="/">product name goes here</a>
                      </h3>
                      <h4 className="product-price">
                        $980.00 <del className="product-old-price">$990.00</del>
                      </h4>

                      <div className="product-btns">
                        <button className="add-to-wishlist">
                          <i className="fa fa-heart-o"></i>
                          <span className="tooltipp">add to wishlist</span>
                        </button>
                        <button className="add-to-compare">
                          <i className="fa fa-exchange"></i>
                          <span className="tooltipp">add to compare</span>
                        </button>
                      </div>
                      <div className="product-rating"></div>
                      <div className="add-to-cart">
                        <a href="/inventory_single">
                          {" "}
                          <button className="add-to-cart-btn">
                            <i className="fa fa-eye"></i> View now
                          </button>
                        </a>
                      </div>
                    </div>
                  </div>
                </a>
              </div> */}

          <div className="clearfix visible-sm visible-xs"></div>

          {/* <!-- /product --> */}
        </div>
        {/* <!-- /row --> */}
      </div>
    </>
  );
}

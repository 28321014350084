import React, { useState } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
// import { Link } from "react-router-dom";
const apUrl = "https://api.autosansar.com/api/contact";

export default function Contact() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [successMessage, setSuccessMessage] = useState("");
  const [error, setError] = useState(null);
  const onSubmit = async (data) => {
    try {
      const response = await axios.post(apUrl, data);
      console.log("Form submission successful:", response.data);
      setSuccessMessage("Thankyou for your message !!");
    
    } catch (error) {
      console.error("Form submission error:", error);
      setError("An error occurred while sending data");
    
    }
  };
  const closeSuccessMessage = () => {
    setSuccessMessage(""); // Clears the success message
  };

  return (
    <>
      {/* <!-- BREADCRUMB --> */}
      <div id="breadcrumb" className="section">
        {/* <!-- container --> */}
        <div className="container">
          {/* <!-- row --> */}
          <div className="row">
            <div className="col-md-12">
              <h3 className="breadcrumb-header">Regular Page</h3>
              <ul className="breadcrumb-tree">
                <li>
                  <a href="/">Home</a>
                </li>
                <li className="active">Contact</li>
              </ul>
            </div>
          </div>
          {/* <!-- /row --> */}
        </div>
        {/* <!-- /container --> */}
      </div>
      {/* <!-- /BREADCRUMB --> */}

      <div className="section">
        {/* <!-- container --> */}
        <div className="container">
          {/* <!-- row --> */}
          <div className="row">
            {/* <!-- Order Details --> */}
            <div className="col-md-6 ">
              <div className="section-title">
                <h3 className="title">Contact Info</h3>
              </div>
              <ul className="list-unstyled contact-links ">
                <li style={{ margin: "3px" }}>
                  <i className="fa-solid fa-location-crosshairs "></i> PO Box
                  1025MNO Collins Street West Victoria 8007 Australia
                </li>
                <li style={{ margin: "3px" }}>
                  <i className="fa-solid fa-phone "></i> 977+98
                </li>
                <li style={{ margin: "3px" }}>
                  <i className="fa-solid fa-envelope "></i> AutoSansar@gmail.com
                </li>
              </ul>

              <div className="section">
                <iFrame
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3531.9351874740432!2d85.32380066453864!3d27.719287331575128!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb19104e848afd%3A0x8787fae4faf4fc15!2sGairidhara%2C%20Kathmandu%2044600!5e0!3m2!1sen!2snp!4v1661249153696!5m2!1sen!2snp"
                  width="550"
                  height="450"
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iFrame>
              </div>
            </div>
            {/* <!-- /Order Details --> */}
            <div className="col-md-6">
              {/* <!-- Billing Details --> */}
              <div className="col mt-4">
                <div className="section-title">
                  <h3 className="title">Get in touch </h3>
                </div>
                <div className="caption" >
                {successMessage && (
                    <div className="alert alert-success alert-dismissible" style={{ display: "flex", justifyContent: "space-between" }}>
                      {successMessage}
                      <button
                        type="button"
                        className="btn-close text-right"
                        style={{ justifyContent:" end",border:"none",background:"none" }}
                        onClick={closeSuccessMessage}
                       
                      ><i class="fa-solid fa-x" ></i></button>
                      
                    </div>
                  )}
                  {error && <div className="alert alert-danger">{error}</div>}
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                      <div className="">
                        <div className="form-group mb-3">
                          <input
                            type="text"
                            className="form-control theme-light"
                            placeholder="Full name"
                            {...register("name", { required: true })}
                          />
                          {errors.name && errors.name.type === "required" && (
                            <span className="invalid-feedback">
                              Name is required
                            </span>
                          )}
                        </div>
                      </div>
                      {/* End .col-6 */}
                      <div className="col-12">
                        <div className="form-group mb-3">
                          <input
                            type="Email"
                            className="form-control theme-light"
                            placeholder="Email"
                            {...register("email", { required: true })}
                          />
                          {errors.email && (
                            <span className="invalid-feedback">
                              Email is required.
                            </span>
                          )}
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="form-group mb-3">
                          <input
                            type="number"
                            className="form-control theme-light"
                            placeholder="Number"
                            {...register("phone_no", { required: true })}
                          />
                          {errors.phone_no && (
                            <span className="invalid-feedback">
                              Number is required.
                            </span>
                          )}
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="form-group mb-3">
                          <input
                            type="text"
                            className="form-control theme-light"
                            placeholder="Subject"
                            {...register("subject", { required: true })}
                          />
                          {errors.subject && (
                            <span className="invalid-feedback">
                              Subject is required.
                            </span>
                          )}
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="form-group mb-3">
                          <input
                            type="text"
                            className="form-control theme-light"
                            placeholder="Address"
                            {...register("address", { required: true })}
                          />
                          {errors.address && (
                            <span className="invalid-feedback">
                              Subject is required.
                            </span>
                          )}
                        </div>
                      </div>
                      {/* End .col-12 */}

                      <div className="col-12">
                        <div className="form-group mb-3">
                          <textarea
                            rows="4"
                            className="form-control theme-light"
                            placeholder="Message"
                            {...register("message", { required: true })}
                          ></textarea>
                          {errors.message && (
                            <span className="invalid-feedback">
                              Comment is required.
                            </span>
                          )}
                        </div>
                      </div>
                      {/* End .col-12 */}

                      <div className="col-12">
                        <div className="btn-bar">
                          <button className="primary-btn order-submit">
                            Send Message
                          </button>
                        </div>
                      </div>
                      {/* End .col-12 */}
                    </div>

                    {/* ... End of your form ... */}
                  </form>
                </div>
              </div>
              {/* <!-- /Billing Details --> */}
            </div>
          </div>
          {/* <!-- /row --> */}
        </div>
        {/* <!-- /container --> */}
      </div>
    </>
  );
}

import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

export default function AccessoriesListed() {
  const [list, setList] = useState([]);
  useEffect(() => {
    axios
      .get("https://api.autosansar.com/api/accessories?limit=3")
      .then((res) => {
        console.log(res);
        setList(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <>
      {list.map((item, id) => (
        <div className="product-widget" key={id}>
          <div className="product-img">
            {item.accessoryphotos.length > 0 && (
              <img
                src={`https://api.autosansar.com/${item.accessoryphotos[0].name}`}
                className="p-image"
                alt=""
              />
            )}
          </div>
          <div className="product-body">
            {/* <p className="product-category">{item.type.name}</p> */}
            <h3 className="product-name">
              <Link to={`/AccessoriesSingle/${item.id}`}>
                {" "}
                {item.name}
                {"  "} {item.metaname}
              </Link>
            </h3>
            <h4 className="product-price">
              {item.price} <del className="product-old-price">{item.price}</del>
            </h4>
          </div>
        </div>
      ))}
    </>
  );
}

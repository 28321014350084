import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function SlickHome() {
  const [slick, setSlick] = useState([]);
  useEffect(() => {
    axios
      .get("https://api.autosansar.com/api/vehicles?limit=16")
      .then((response) => {
        if (response.status === 200) {
          const featured = response.data.data
            .filter(
              (product) =>
                product.is_featured === 1 &&
                product.brand !== null &&
                product.brand.name !== null
            )
            .slice(0, 12);
          // const oldProducts = response.data.data.filter(product => product.condition.name === 'Old');
          setSlick(featured);
        } else {
          console.error(
            "Error fetching data from the API:",
            response.status,
            response.statusText
          );
        }
      })
      .catch((error) => {
        console.error("Error fetching data from the API:", error.message);
      });
  }, []);

  const settings = {
    className: "center",
    infinite: false,
    loop: true,
    centerPadding: "60px",
    slidesToShow: 4,
    swipeToSlide: true,
    // dots:true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    afterChange: function (index) {
      console.log(
        `Slider Changed to: ${index + 1}, background: #222; color: #bada55`
      );
    },
  };
  return (
    <>
      <Slider {...settings}>
        {slick.map((item, id) => (
          <div className="col-md-3 col-xs-6" key={id}>
            <Link to={`/Inventory_single/${item.id}`}>
              <div className="product">
                <div className="product-img">
                {item.vehiclephotos.length > 0 && (
                    <img
                     
                      src={`https://api.autosansar.com/${item.vehiclephotos[0].name}`}
                      className="p-image"
                      alt=""
                    />
                 ) }

                  <div className="product-label">
                    {/* <span className="sale">-30%</span> */}
                    <span className="new">{item.condition.name}</span>
                  </div>
                </div>
                <div className="product-body">
                  <p className="product-category">{item.type.name}</p>
                  <h3 className="product-name">
                    <a href="/">
                      {" "}
                      {item.brand?.name}
                      {"  "} {item.car_model}
                    </a>
                  </h3>
                  <h4 className="product-price">
                    {item.price}{" "}
                    <del className="product-old-price"> {item.oldprice}</del>
                  </h4>

                  <div className="product-btns">
                    <button className="add-to-wishlist">
                      <i className="fa fa-heart-o"></i>
                      <span className="tooltipp">add to wishlist</span>
                    </button>
                    <button className="add-to-compare">
                      <i className="fa fa-exchange"></i>
                      <span className="tooltipp">add to compare</span>
                    </button>
                  </div>
                  <div className="product-rating"></div>
                  <div className="add-to-cart">
                    <Link to={`/Inventory_single/${item.id}`}>
                      {" "}
                      <button className="add-to-cart-btn">
                        <i className="fa fa-eye"></i> View now
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        ))}
      </Slider>
    </>
  );
}

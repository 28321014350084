import React, { useState, useEffect } from "react";
import axios from "axios";
import "./App.css";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Inventory_single from "./components/Inventory_single";
import Blogsingle from "./components/Blogsingle";
import Inventory from "./components/Inventory";
import Blog from "./components/Blog";
import Contact from "./components/Contact";
import Home from "./components/Home";
import About from "./components/About";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  // Link
} from "react-router-dom";
import SingleBlog from "./components/SingleBlog";
import Accessories from "./components/Accessories/Accessories";
import AccessoriesSingle from "./components/Accessories/AccessoriesSingle";
import Car from "./Inventory Type/Car";
import Bike from "./Inventory Type/Bike";
import Scooter from "./Inventory Type/Scooter";
import InventorySidebar from "./components/InventorySidebar";
import Search from "./components/search/Search";
import DetailSearch from "./components/search/DetailSearch";
import BreadCrumb from "./components/breadcrumb";
import AccessoriesListed from "./components/Listed/AccessoriesListed";

function App() {
  // const [type, setType] = useState([]);

  // const [inventory, setInventory] = useState([]);
  // useEffect(() => {
  //   axios
  //     .get(
  //       "https://api.autosansar.com/api/vehicles/search"
  //     )
  //     .then((res) => {
  //       console.log(res);
  //       setInventory(res.data.data);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }, []);

  // input
  // const [query, setQuery] = useState("");

  // const handleInputChange = (e) => {
  //   setQuery(e.target.value);
  // };
  //  const filterdItem = inventory.filter((item)=>
  // item.data.toLocalLowerCase().indexOf(query.toLocaleLowerCase() !== -1)
  // );

  // const handleChange = (e) => {
  //   setType(e.target.value);
  // };

  // function filteredData(selected, query,inventory) {
  //    let filteredProducts = inventory
  //   if (query) {
  //     filteredProducts=filterdItem
  //   }

  //   if(selected){
  //     filteredProducts=filteredProducts.filter(({type,modal,brand,location,price})=> type === selected || modal === selected || brand === selected || location === selected || price === selected )
  //   }
  // }


  return (
    <>
      <Router>
        <Navbar />
       
        <Routes>
          {/* <Route exact path="/home" element={<Home />} /> */}
          <Route exact path="/" element={<Home />} />

          <Route exact path="/about" element={<About />} />

          <Route exact path="/Accessories" element={<Accessories />} />
          <Route exact path="/Blog" element={<Blog />} />
          <Route exact path="/Inventory" element={<Inventory />} />
          <Route exact path="/Inventory/:id" element={<Inventory />} />
          <Route exact path="/Contact" element={<Contact />} />
          <Route exact path="/Car" element={<Car />} />

          <Route exact path="/search" element={<Search   /> } />

          <Route exact path="/search" element={<Search />} />

          <Route exact path="/Bike" element={<Bike />} />
          <Route exact path="/Scooter" element={<Scooter />} />
          <Route exact path="/Blogsingle/:slug" element={<Blogsingle />} />
          {/* <Route exact path="/SingleBlog/:slug" element={<SingleBlog />} /> */}
          <Route exact path="/DetailSearch" element={ <DetailSearch/>} />
          <Route exact path="/DetailSearch/:id" element={ <DetailSearch/>} />
          <Route exact path="/AccessoriesListed" element={ <AccessoriesListed/>} />
          <Route
            exact
            path="/Inventory_single/:id"
            element={<Inventory_single />}
          />
          <Route
            exact
            path="/InventorySidebar"
            element={<InventorySidebar />}
          />
          <Route
            exact
            path="/AccessoriesSingle/:id"
            element={<AccessoriesSingle />}
          />
          {/* <Route exact path="/inve/:slug" element={<Singleblog/>}/> */}
        </Routes>
        <Footer />
        {/* <InventorySearch/> */}
        {/* <Scooter/> */}
      </Router>
  
    </>
  );
}

export default App;

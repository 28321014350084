import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import InventorySearch from "./InventorySearch";

function Search() {
  const [search, setSearch] = useState("");
  const [sInventory, setSInventory] = useState([]);
  const [category, setCategory] = useState("0");
  const [submitted, setSubmitted] = useState(false);
  const [noResults, setNoResults] = useState(false);
  const [showResults, setShowResults] = useState(false); // State variable to manage the visibility of search results
  const navigate = useNavigate();

  const handleChange = (e) => {
    setSearch(e.target.value);
  };

  useEffect(() => {
    if (search !== "") {
      let apiUrl;
      if (category === "0") {
        apiUrl = `https://api.autosansar.com/api/vehicles/search?search=${search}`;
      } else if (category === "1") {
        apiUrl = `https://api.autosansar.com/api/accessories/search?search=${search}`;
      }

      fetch(apiUrl)
        .then((res) => res.json())
        .then((data) => {
          if (data && data.data && Array.isArray(data.data)) {
            setSInventory(data.data);
            setNoResults(data.data.length === 0);
            setShowResults(true); // Show search results when data is fetched
          } else {
            console.error("Fetched data is not in the expected format:", data);
            setSInventory([]);
            setShowResults(false); // Hide search results if data is not in the expected format
          }
        })
        .catch((error) => {
          console.error("Error fetching inventory data:", error);
          setSInventory([]);
          setShowResults(false); // Hide search results on error
        });
    } else {
      setSInventory([]);
      setNoResults(false);
      setShowResults(false); // Hide search results when search query is empty
    }
  }, [search, category]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let Url;
      let dataType; // Variable to store the type of data being fetched
      if (category === "0") {
        Url = `https://api.autosansar.com/api/vehicles/search?search=${search}&&limit=6`;
        dataType = "vehicles"; // Set the type of data to "vehicles"
      } else if (category === "1") {
        Url = `https://api.autosansar.com/api/accessories/search?search=${search}limit=6`;
        dataType = "accessories"; // Set the type of data to "accessories"
      }
  
      const response = await fetch(Url);
      const data = await response.json();
      if (data && data.data && Array.isArray(data.data)) {
        setSInventory(data.data);
        setSubmitted(true);
        const encodedData = encodeURIComponent(JSON.stringify(data.data));
        // Include the type of data in the URL
        const url = `/DetailSearch?sInventory=${encodedData}&type=${dataType}`;
        navigate(url);
        setShowResults(false); // Hide search results when submit button is clicked
      } else {
        console.error("Fetched data is not in the expected format:", data);
        setShowResults(false); // Hide search results if data is not in the expected format
      }
    } catch (error) {
      console.error("Error fetching inventory data:", error);
      setSInventory([]);
      setShowResults(false); // Hide search results on error
    }
  };
  

  return (
    <>
      <div className="header-search">
        <form onSubmit={handleSubmit}>
          <select
            className="input-select"
            onChange={(e) => setCategory(e.target.value)}
            value={category}
          >
            <option value="0">Inventory</option>
            <option value="1">Accessories</option>
          </select>
          <input
            className="input"
            type="text"
            name="search"
            placeholder="Search here"
            value={search}
            onChange={handleChange}
          />
          <button className="search-btn" type="submit" disabled={!search.trim() || noResults}>
            Search
          </button>
        </form>

        {showResults && search !== "" && (
          <div className="dataResult">
            {noResults ? (
              <div className="noResults">No results found</div>
            ) : (
              <>
                {category === "0" &&
                  sInventory.map((data, id) => (
                    <Link to={`/Inventory_single/${data.id}`} className="dataItem input" key={id}>
                      {data.car_model} {data.brand.name}
                    </Link>
                  ))}
                {category === "1" &&
                  sInventory.map((data, id) => (
                   
                     <Link to={`/AccessoriesSingle/${data.id}`} className="dataItem input" key={id}>
                     {data.name}{data.metaname}
                   </Link>
                  ))}
              </>
            )}
          </div>
        )}
      </div>
    </>
  );
}

export default Search;

import React, { useState ,useEffect} from "react";
import Price from "../Price";
import AccessoriesListed from "../Listed/AccessoriesListed";



function AccessoriesSidebar({accessory,applyFilters ,value, onChange}) {
 
   const [selectedLocations, setSelectedLocations] = useState([]);
   const [selectedTypes, setSelectedTypes] = useState([]);

  

   const [allLocations, setAllLocations] = useState([]);
   const [allTypes, setAllTypes] = useState([]);
  

   useEffect(() => {
    const getUniqueValues = (property) => {
      return accessory.reduce((acc, item) => {
        if (item[property] && item[property].name && !acc.includes(item[property].name)  ) {
          acc.push(item[property].name);
        }
     
        return acc;
      }, []);
    };
  
  
   
    setAllTypes(getUniqueValues("accessorycategory"));
    // setAllModels(getUniqueValues("car_model"));
  }, [accessory]);

  useEffect(() => {
    const getUniqueValues = (property) => {
      return accessory.reduce((acc, item) => {
        if (item[property] && item[property] && !acc.includes(item[property])  ) {
          acc.push(item[property]);
        }
        return acc;
      }, []);
    };
  
    setAllLocations(getUniqueValues("location"));
  }, [accessory]);   
  
  
  const handleLocationSelect = (location) => {
    const updatedLocations = selectedLocations.includes(location)
      ? selectedLocations.filter((l) => l !== location)
      : [...selectedLocations, location];
    console.log("Selected Locations:", updatedLocations); 
    setSelectedLocations(updatedLocations);
    applyFilters({ selectedLocations: updatedLocations });
  };
  
  const handleTypeSelect = (category) => {
    const updatedTypes = selectedTypes.includes(category)
      ? selectedTypes.filter((t) => t !== category)
      : [...selectedTypes, category];
    console.log("Selected Types:", updatedTypes); 
    setSelectedTypes(updatedTypes);
    applyFilters({ selectedTypes: updatedTypes });
  };
    



  return (
    <>
      <div id="aside" className="col-md-3">
      {/* Location */}
      <div className="aside">
        <h3 className="aside-title">Location</h3>
        <div className="checkbox-filter">
          {allLocations.map((location) => (
            <div className="input-checkbox" key={location}>
              <input
                type="checkbox"
                id={`location-${location}`}
                checked={selectedLocations.includes(location)}
                onChange={() => handleLocationSelect(location)}
              />
              <label htmlFor={`location-${location}`}>
                <span></span>
                {location}
              </label>
            </div>
          ))}
        </div>
      </div>

        {/* Categories */}
        <div className="aside">
          <h3 className="aside-title">Category</h3>
          <div className="checkbox-filter">
          {allTypes.map((category) => (
            <div className="input-checkbox" key={category}>
              <input
                type="checkbox"
                id={`type-${category}`}
                checked={selectedTypes.includes(category)}
                onChange={() => handleTypeSelect(category)}
              />
              <label htmlFor={`type-${category}`}>
                <span></span>
                {category}
              </label>
            </div>
          ))}
        </div>
        </div>

        <div className="aside">
          <h3 className="aside-title">Price</h3>
          <div className="price-filter">
            <Price  value={value} onChange={onChange} />
          </div>
        </div>

        {/* Recently Listed */}
        <div className="aside">
          <h3 className="aside-title">Recently Listed</h3>
     <AccessoriesListed/>
        </div>

        {/* Price */}
    
      </div>
    </>
  );
}

export default AccessoriesSidebar;

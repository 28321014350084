import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

export default function PopularPostBlog() {
    const [list, setList] = useState([]);
  useEffect(() => {
    axios
      .get(
        "https://nepalautotrader.com/api/blogs?limit=3&orderBy=created_at DESC"

      )
      .then((res) => {
        console.log(res);
        setList(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);


  return (
    <>
       {list.map((item, id) => (
      <div className="product-widget" key={id}>
        <div className="product-img">
          <img    src={item.blog_image.original} alt="" />
        </div>
        <div className="product-body">
          <p className="product-category"> {item.created_at}</p>
          <h3 className="product-name">
            <Link to={`/Blogsingle/${item.slug}`}>  {item.blog_title.slice(0, 20)}</Link>
          </h3>
        </div>
      </div>
      ))}
    </>
  );
}

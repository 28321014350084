import React from "react";
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import Input from '@mui/material/Input';

function valuetext(value) {
  return `${value}°C`;
}

export default function RangeSlider({ value, onChange }) {
  const handleChange = (event, newValue) => {
    onChange(newValue);
  };

  const handleInputChange = (index) => (event) => {
    const newValue = event.target.value === '' ? 0 : Number(event.target.value);
    const newValues = [...value];
    newValues[index] = newValue;
    onChange(newValues);
  };

  const handleIncrement = (index) => () => {
    const newValues = [...value];
    newValues[index] = Math.min(newValues[index] + 1000, 1000000);
    onChange(newValues);
  };

  const handleDecrement = (index) => () => {
    const newValues = [...value];
    newValues[index] = Math.max(newValues[index] - 1000, 0);
    onChange(newValues);
  };

  return (
    <Box sx={{ width: 250 }}>
        <Slider
        getAriaLabel={() => 'Temperature range'}
        value={value}
        onChange={handleChange}
        valueLabelDisplay="auto"
        getAriaValueText={valuetext}
        style={{color:"#F56B13"}}
        min={0}
        max={1000000}
      />
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <div className="input-number price-min">
          <Input
            id="price-min"
            type="number"
            value={value[0]}
            onChange={handleInputChange(0)}
            inputProps={{
              step: 100,
              min: 0,
              max: 1000000,
              type: 'number',
              'aria-labelledby': 'input-slider',
            }}
          />
     
          <IconButton color="primary" className="qty-up" onClick={handleIncrement(0)} style={{padding:"2%"}}>
            <AddIcon />
          </IconButton>
          <IconButton color="primary" className="qty-down" onClick={handleDecrement(0)} style={{padding:"2%"}}>
            <RemoveIcon />
          </IconButton>
        </div>
        <span>-</span>
        <div className="input-number price-max">
          <Input
            id="price-max"
            type="number"
            value={value[1]}
            onChange={handleInputChange(1)}
            inputProps={{
              step: 10,
              min: 0,
              max: 10000,
              type: 'number',
              'aria-labelledby': 'input-slider',
            }}
          />
          <IconButton color="primary"className="qty-up" onClick={handleIncrement(1)} style={{padding:"2%"}}>
            <AddIcon />
          </IconButton>
          <IconButton color="primary"  className="qty-down" onClick={handleDecrement(1)} style={{padding:"2%"}}>
            <RemoveIcon />
          </IconButton>
        </div>
      </Box>
    </Box>
  );
}

import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

export default function RecentlyListed() {
  const [list, setList] = useState([]);
  useEffect(() => {
    axios
      .get("https://api.autosansar.com/api/vehicles?limit=3")
      .then((res) => {
        console.log(res);
        setList(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <>
      {list.map((item, id) => (
        <div className="product-widget" key={id}>
          <div className="product-img">
          {item.vehiclephotos.length > 0 && (
                      <img
                        src={`https://api.autosansar.com/${item.vehiclephotos[0].name}`}
                        className=""
                        alt=""
                      />
                    )}
          </div>
          <div className="product-body">
            <p className="product-category">{item.type.name}</p>
            <h3 className="product-name">
            <Link to={`/Inventory_single/${item.id}`}> {" "}
                        {item.brand?.name}
                        {"  "} {item.car_model}</Link>
            </h3>
            <h4 className="product-price">
            {item.price} <del className="product-old-price"> $980.00</del>
            </h4>
          </div>
        </div>
      ))}
    </>
  );
}

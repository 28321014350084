
import React, { useEffect, useState } from "react";
import { useLocation, useParams, Link } from "react-router-dom";
import axios from "axios";
import InventorySidebar from "../InventorySidebar";

const DetailSearch = () => {
  const location = useLocation();
  const { id } = useParams();
  const [sInventory, setSInventory] = useState([]);
  const [itemDetails, setItemDetails] = useState(null);
  const [dataType, setDataType] = useState(null); // State to store the type of data

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const encodedData = queryParams.get("sInventory");
    const type = queryParams.get("type"); 
    if (encodedData) {
      try {
        const decodedData = JSON.parse(decodeURIComponent(encodedData));
        setSInventory(decodedData);
        // Reset itemDetails when new inventory data is received
        setItemDetails(null);
        setDataType(type); // Set the type of data received
      } catch (error) {
        console.error("Error parsing received data:", error);
      }
    }
  }, [location.search]);

  useEffect(() => {
    if (id) {
      axios
        .get(`https://api.autosansar.com/api/vehicles/${id}`)
        .then((res) => {
          console.log(res);
          setItemDetails(res.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [id]);

  return (
    <div className="section">
    <div className="container">
      <div className="row">
        {/* <InventorySidebar /> */}
        <div id="store" className="col-md-9">
          {dataType === "vehicles" ? (
            
            <div className="row">
                {sInventory.map((item, id) => (
               <div className="col-md-4 col-xs-6" key={item.id}>
               <div className="product">
                 {/* Product details */}
                 <div className="product-img">
                   {item.vehiclephotos.length > 0 && (
                     <img
                       src={`https://api.autosansar.com/${item.vehiclephotos[0].name}`}
                       className="p-image"
                       alt=""
                     />
                   )}
                   <div className="product-label">
                     <span className="new">{item.condition.name}</span>
                   </div>
                 </div>
                 <div className="product-body">
                   <p className="product-category">{item.type.name}</p>
                   <h3 className="product-name">
                     <a href="/">
                       {item.brand?.name} {item.car_model}
                     </a>
                   </h3>
                   <h4 className="product-price">
                     {item.price}{" "}
                     <del className="product-old-price">
                       {item.oldprice}
                     </del>
                   </h4>
                   <div className="product-btns">
                     <button className="add-to-wishlist">
                       <i className="fa fa-heart-o"></i>
                       <span className="tooltipp">add to wishlist</span>
                     </button>
                     <button className="add-to-compare">
                       <i className="fa fa-exchange"></i>
                       <span className="tooltipp">add to compare</span>
                     </button>
                   </div>
                   <div className="product-rating"></div>
                   <div className="add-to-cart">
                     <Link to={`/Inventory_single/${item.id}`}>
                       <button className="add-to-cart-btn">
                         <i className="fa fa-eye"></i> View now
                       </button>
                     </Link>
                   </div>
                 </div>
               </div>
             </div>
             ))}
            </div>
          ) : dataType === "accessories" ? (
           
            <div className="row">
              {sInventory.map((item, id) => (
               <div className="col-md-4 col-xs-6" key={id}>
               <Link to={`/AccessoriesSingle/${item.id}`}>
                 <div className="product">
                   <div className="product-img">
                   {item.accessoryphotos.length > 0 && (
                             <img
                              
                               src={`https://api.autosansar.com/${item.accessoryphotos[0].name}`}
                               className="p-image"
                               alt=""
                             />
                          ) } 
                   </div>
                   <div className="product-body">
                     <p className="product-category">
                       {/* {item.accessorycategory?.name} */}
                     </p>
                     <h3 className="product-name">
                       <a href="/">
                         {" "}
                         {item.name}
                         {"  "} {item.metaname}
                       </a>
                     </h3>
                     <h4 className="product-price">
                       {item.price}{" "}
                       {/* <del className="product-old-price"> {item.oldprice}</del> */}
                     </h4>

                     <div className="product-btns">
                       <button className="add-to-wishlist">
                         <i className="fa fa-heart-o"></i>
                         <span className="tooltipp">add to wishlist</span>
                       </button>
                       <button className="add-to-compare">
                         <i className="fa fa-exchange"></i>
                         <span className="tooltipp">add to compare</span>
                       </button>
                     </div>
                     <div className="product-rating"></div>
                     <div className="add-to-cart">
                       <Link to={`/AccessoriesSingle/${item.id}`}>
                         {" "}
                         <button className="add-to-cart-btn">
                           <i className="fa fa-eye"></i> View now
                         </button>
                       </Link>
                     </div>
                   </div>
                 </div>
               </Link>
             </div>
              ))}
            </div>
          ) : (
           
            <div>No data available</div>
          )}
        </div>
      </div>
    </div>
    </div>
  );
};

export default DetailSearch;
